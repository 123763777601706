<template>
  <footer class="footer" :class="{ 'footer__mobile-hide': hideFooter }">
    <div class="container">
      <div class="footer__inner">
        <div class="footer__top" v-if="!isService">
          <div class="footer__nav">
            <ul class="footer__nav-row">
              <li class="footer__nav-col" v-for="(item, index) in footerNav" :key="index">
                <h4>{{ item.title }}</h4>
                <ul class="nav__col__links">
                  <li v-for="(link, index) in item.links" :key="index">
                    <router-link :to="{ name: link.route }" class="footer__nav__link">
                      {{ link.title }}
                    </router-link>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        </div>
        <div class="footer__bottom">
          <ul class="footer__bottom-row">
            <li class="footer__bottom-col">
              <ul class="footer__bottom__sites">
                <li v-for="(item, i) in links" :key="i">
                  <a @click.prevent="showLinksModal(item.code)" href="#">{{ item.title }}</a>
                </li>
              </ul>
            </li>
            <li class="footer__bottom-col footer__socials">
              <div class="footer__socials__title">Страницы мэра в соцсетях</div>
              <ul v-if="socials && socials.length" class="footer__socials__list">
                <li class="footer__socials__item" v-for="(item, index) in socials" :key="index">
                  <a :title="item.title" :href="item.link" rel="nofollow" target="_blank">
                    <figure v-if="item.type && item.type.icon" class="icon" v-html="item.type.icon" />
                  </a>
                </li>
              </ul>
            </li>
            <li class="footer__bottom-col">
              <ul class="footer__useful__links">
                <li>
                  <a
                    @click.prevent="showLinksModal($store.state.type.CODE_USEFUL_LINK)"
                    class="footer__useful__link"
                    href="#"
                  >
                    Полезные ссылки
                  </a>
                </li>
                <li>
                  <a @click.prevent="showSupportModal" class="footer__useful__link" href="#">Техподдержка</a>
                </li>
                <li>
                  <router-link :to="{ name: 'sitemap' }" class="footer__useful__link">
                    Карта сайта
                  </router-link>
                </li>
              </ul>
            </li>
            <ul class="footer__info-col">
              <li class="footer__bottom-col">
                <button @click="toggleUserWay" class="btn-black blind__people__version" type="button">
                  Версия для слабовидящих
                </button>
              </li>
              <li v-if="agreement" class="agreement__link">
                <a :href="$store.state.api + agreement.url" target="_blank" download>
                  Соглашение о пользовании информационными системами и ресурсами города Махачкалы
                </a>
              </li>
              <!--              <li class="footer__logo">-->
              <!--                <a href="https://cron.ooo/" target="_blank">-->
              <!--                  <LogoCron />-->
              <!--                </a>-->
              <!--              </li>-->
            </ul>
          </ul>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
import LinksModal from "components/modals/LinksModal.vue";
import SupportModal from "components/modals/SupportModal.vue";
import LogoCron from "components/svg/LogoCron.vue";

export default {
  name: "FooterComponent",
  components: { LogoCron },
  data() {
    return {
      links: [
        {
          title: "Сайты Махачкалы",
          code: this.$store.state.type.CODE_MKALA_LINKS,
        },
        {
          title: "Сайты Дагестана",
          code: this.$store.state.type.CODE_DAGESTAN_LINKS,
        },
        {
          title: "Сайты России",
          code: this.$store.state.type.CODE_RUSSIAN_LINKS,
        },
      ],
      footerNav: [
        {
          title: "Услуги",
          links: [
            {
              title: "Каталог услуг",
              route: "services_catalog",
            },
            {
              title: "Центры оказания услуг",
              route: "service_centers",
            },
            {
              title: "Вопросы и ответы",
              route: "faq",
            },
          ],
        },
        {
          title: "Муниципалитет",
          links: [
            {
              title: "Структура",
              route: "localStructure",
            },
            {
              title: "Органы власти",
              route: "localGovernments",
            },
            {
              title: "Деятельность",
              route: "localActivity",
            },
          ],
        },
        {
          title: "Документы",
          links: [
            {
              title: "Нормативно-правовые акты",
              route: "documents",
            },
            {
              title: "Заявления и формы запросов",
              route: "documents",
            },
          ],
        },
        {
          title: "Мой город",
          links: [
            {
              title: "Общие сведения",
              route: "mycity",
            },
            {
              title: "Медиа",
              route: "media",
            },
            {
              title: "Справочные данные",
              route: "mycity_reference",
            },
          ],
        },
        {
          title: "Обратная связь",
          links: [
            {
              title: "Контакты",
              route: "feedback",
            },
            {
              title: "Официальное обращение",
              route: "official_appeal",
            },
            {
              title: "Прием граждан и корреспонденции",
              route: "feedback_reception",
            },
          ],
        },
      ],
    };
  },
  computed: {
    socials() {
      return this.$store.state.socials || [];
    },
    agreement() {
      return this.$store.state.agreement?.join_documents[0]?.document;
    },
    isService() {
      return ["sc", "sc_map", "sc_about", "sc_themes", "sc_results", "pd", "pd_open"].includes(
        this.$route.name
      );
    },
    hideFooter() {
      return ["sc_map"].includes(this.$route.name);
    },
  },
  methods: {
    showLinksModal(code) {
      this.$store.state._modals.push({
        component: LinksModal,
        options: {
          code: code,
        },
      });
    },
    showSupportModal() {
      this.$store.state._modals.push({
        component: SupportModal,
      });
    },
    toggleUserWay() {
      window.UserWay.widgetToggle();
    },
  },
};
</script>

<style lang="stylus">
.footer
  margin-top: auto;

  &__logo {
    svg {
      width: 60px
      height: 60px
    }
    +below(990px){
      display flex
      justify-content center
      margin-top: 20px
    }
  }

  &__info-col {
    display: grid;
    grid-template-columns: auto 1fr auto;
    grid-gap: 48px;
    max-width 667px
    +below(1250px) {
      grid-gap 24px
    }
    +below(1050px) {
      grid-template-columns: 1fr;
      grid-gap 10px
    }
  }

  &__mobile-hide {
    +below(880px) {
      display none
    }
  }

  .container
    display flex
    flex-direction column
    align-items stretch
    justify-content flex-start

  &__inner
    color: var(--color_dark)
    background-color var(--color_gray)

  &__top
    padding 32px
    border-bottom 1px solid var(--main_border_color);
    +below(768px) {
      display none
    }


  &__nav {
    &-row {
      display grid
      grid-template-columns repeat(9, auto)
      grid-gap 45px
      +below(1280px) {
        grid-gap 24px
      }
      +below(1180px) {
        grid-template-columns repeat(5, auto)
      }
      +below(990px) {
        grid-template-columns repeat(3, auto)
      }
    }

    &__link {
      display flex
      font-size: 0.875em
      line-height: 20px
    }
  }

  .nav__col__links
   > li
     margin-bottom: 12px
     &:last-child
       margin-bottom: 0

  &__bottom
    padding 32px
    +below(768px) {
      padding 20px 24px 32px
    }
    +below(380px) {
      padding 32px 16px
    }

    &-row {
      display grid
      grid-template-columns: 0.9fr 1fr 0.8fr 3fr;
      grid-gap 11px
      +below(990px){
        grid-template-columns: 0.9fr 1fr 0.8fr 2fr;
      }
      +below(768px) {
        grid-template-columns repeat(2, 1fr)
        row-gap 20px
      }
      +below(460px) {
        grid-template-columns repeat(1, 1fr)
        row-gap 32px
      }
    }

    &__sites
      > li
        font-weight: 500;
        font-size: 1em
        line-height: 24px
        margin-bottom: 20px;
        +below(420px) {
          margin-bottom: 16px;
        }

        &:last-child
          margin-bottom: 0;

  &__socials
    &__title
      font-size: 0.875em
      line-height: 20px;
      margin-bottom: 16px

    &__list
      display flex
      align-items center
      margin -6px

    &__item {
      a {
        display flex
        padding 6px

        .icon {
          display flex

          svg {
            +below(768px) {
              width: 32px
              height: 32px
            }
            +below(540px) {
              width: 24px
              height: 24px
            }
            +below(420px) {
              width: 32px
              height: 32px
            }
          }
        }
      }
    }

  &__useful__links {
    li:not(:last-child) {
      margin-bottom: 20px
      +below(420px) {
        margin-bottom: 16px;
      }
    }
  }

  &__useful__link {
    display flex
    font-size 0.875em
    line-height 20px
  }

  .blind__people__version
    margin-bottom 20px
    +below(1200px) {
      font-weight normal
      text-transform none
      height var(--main_height)
      font-size: 1em;
      line-height: normal;
      padding: 10px 17px;
    }
    +below(420px) {
      width 100%
    }

  .agreement__link {
    display flex
    font-size: 0.875em
    line-height: 20px
    text-decoration-line: underline;
    color: var(--color_dark)
  }
</style>
