export default {
  home_page(state, data) {
    state.home_page = data;
    state.mayor = data.mayor;
    state.agreement = data.agreement;
    if (data.socials) state.socials = data.socials;
    // state.phones = data.phones
    // state.contacts = data.contacts
    // state.navigator = data.navigator
  },
  news_page(state, data) {
    state.news_page = data;
    state.news_actual = data.news_actual.data;
    state.news_types = data.types;
    state.main_categories = data.categories;
    state.mayor = data.mayor;
    state.agreement = data.agreement;
    if (data.socials) state.socials = data.socials;
  },
  news_open_page(state, data) {
    state.news_open_page = data;
    state.news_actual = data.news_actual.data;
    state.mayor = data.mayor;
    state.agreement = data.agreement;
    if (data.socials) state.socials = data.socials;
  },
  posters_page(state, data) {
    state.posters_page = data;
    state.mayor = data.mayor;
    state.agreement = data.agreement;
    if (data.socials) state.socials = data.socials;
  },
  posters_open_page(state, data) {
    state.posters_open_page = data.poster;
    state.mayor = data.mayor;
    state.agreement = data.agreement;
    if (data.socials) state.socials = data.socials;
  },
  documents_page(state, data) {
    state.documents_page = data.documents;
    state.departments = data.departments;
    state.document_types = data.types;
    state.mayor = data.mayor;
    state.agreement = data.agreement;
    if (data.socials) state.socials = data.socials;
  },
  services_page(state, data) {
    state.services_page = data;
    state.banners = data.banners;
    state.mayor = data.mayor;
    state.agreement = data.agreement;
    if (data.socials) state.socials = data.socials;
  },
  service(state, data) {
    state.service = data.service_item;
    state.banners = data.banners;
    state.mayor = data.mayor;
    state.agreement = data.agreement;
    if (data.socials) state.socials = data.socials;
  },
  services_about_page(state, data) {
    state.services_about_page = data;
    state.banners = data.banners;
    state.mayor = data.mayor;
    state.agreement = data.agreement;
    if (data.socials) state.socials = data.socials;
  },
  sc_page(state, data) {
    state.sc_page = data;
    state.mayor = data.mayor;
    state.agreement = data.agreement;
    if (data.socials) state.socials = data.socials;
  },
  sr_page(state, data) {
    state.sr_page = data;
    state.banners = data.banners;
    state.mayor = data.mayor;
    state.agreement = data.agreement;
    if (data.socials) state.socials = data.socials;
  },
  faq_page(state, data) {
    state.faq_page = data.faqs;
    state.banners = data.banners;
    state.mayor = data.mayor;
    state.agreement = data.agreement;
    if (data.socials) state.socials = data.socials;
  },
  mayor_page(state, data) {
    state.mayor_page = data;
    state.mayor = data.mayor;
    state.agreement = data.agreement;
    if (data.socials) state.socials = data.socials;
  },
  mayor_news_page(state, data) {
    state.mayor_news_page = data;
    state.main_categories = data.categories;
    state.mayor = data.mayor;
    state.agreement = data.agreement;
    if (data.socials) state.socials = data.socials;
  },
  mayor_media_page(state, data) {
    state.mayor_media_page = data;
    state.main_categories = data.categories;
    state.mayor = data.mayor;
    state.agreement = data.agreement;
    if (data.socials) state.socials = data.socials;
  },
  mayor_media_open(state, data) {
    state.mayor_media_open = data;
    state.mayor = data.mayor;
    state.agreement = data.agreement;
    if (data.socials) state.socials = data.socials;
  },
  mycity_page(state, data) {
    state.mycity_page = data.my_city;
    state.mayor = data.mayor;
    state.agreement = data.agreement;
    if (data.socials) state.socials = data.socials;
  },
  mycity_history(state, data) {
    state.mycity_history = data.historical_sketch;
    state.mayor = data.mayor;
    state.agreement = data.agreement;
    if (data.socials) state.socials = data.socials;
  },
  smi_page(state, data) {
    state.smi_page = data.smi;
    state.mayor = data.mayor;
    state.agreement = data.agreement;
    if (data.socials) state.socials = data.socials;
  },
  mycity_ref(state, data) {
    state.mycity_ref = data;
    state.mayor = data.mayor;
    state.agreement = data.agreement;
    if (data.socials) state.socials = data.socials;
  },
  mycity_media_page(state, data) {
    state.mycity_media_page = data;
    state.main_categories = data.categories;
    state.mayor = data.mayor;
    state.agreement = data.agreement;
    if (data.socials) state.socials = data.socials;
  },
  mycity_media_open(state, data) {
    state.mycity_media_open = data;
    state.mayor = data.mayor;
    state.agreement = data.agreement;
    if (data.socials) state.socials = data.socials;
  },
  orgs_page(state, data) {
    state.orgs_page = data.departments;
    state.main_categories = data.categories;
    state.mayor = data.mayor;
    state.agreement = data.agreement;
    if (data.socials) state.socials = data.socials;
  },
  org_open_page(state, data) {
    state.org_open_page = data.department;
    state.mayor = data.mayor;
    state.agreement = data.agreement;
    if (data.socials) state.socials = data.socials;
  },
  minister_open_page(state, data) {
    state.minister_open_page = data.minister;
    state.mayor = data.mayor;
    state.agreement = data.agreement;
    if (data.socials) state.socials = data.socials;
  },
  projects(state, data) {
    state.projects = data.projects;
    state.main_categories = data.categories;
    state.mayor = data.mayor;
    state.agreement = data.agreement;
    if (data.socials) state.socials = data.socials;
  },
  government_page(state, data) {
    state.government_page = data;
    state.mayor = data.mayor;
    state.agreement = data.agreement;
    if (data.socials) state.socials = data.socials;
  },
  structure_page(state, data) {
    state.structure_page = data;
    state.mayor = data.mayor;
    state.agreement = data.agreement;
    if (data.socials) state.socials = data.socials;
  },
  activity_page(state, data) {
    state.activity_page = data;
    state.main_categories = data.categories;
    state.mayor = data.mayor;
    state.agreement = data.agreement;
    if (data.socials) state.socials = data.socials;
  },
  feedback_page(state, data) {
    state.feedback_page = data;
    state.mayor = data.mayor;
    state.agreement = data.agreement;
    if (data.socials) state.socials = data.socials;
  },
  appeal_page(state, data) {
    state.appeal_page = data;
    if (state.appeal_page.departments) {
      state.appeal_page.departments.unshift({ id: null, title: "Администрация и список всех Управлений (без Учреждений и организаций)" });
    }
    state.mayor = data.mayor;
    state.agreement = data.agreement;
    if (data.socials) state.socials = data.socials;
  },
  reception_page(state, data) {
    state.reception_page = data.correspondence_and_citizens;
    state.mayor = data.mayor;
    state.agreement = data.agreement;
    if (data.socials) state.socials = data.socials;
  },
  dd_service_page(state, data) {
    state.dd_service_page = data;
    state.mayor = data.mayor;
    state.agreement = data.agreement;
    if (data.socials) state.socials = data.socials;
  },

  socialControlCategories(state, data) {
    state.socialControl.categories = data;
  },
  socialControlStatuses(state, data) {
    state.socialControl.statuses = data;
  },
  socialControlMarkers(state, data) {
    state.socialControl.markers = data;
  },
  sc_about_page(state, data) {
    state.sc_about_page = data.sc_about;
    state.mayor = data.mayor;
    state.agreement = data.agreement;
    if (data.socials) state.socials = data.socials;
  },
  sc_themes(state, data) {
    state.sc_categories = data.categories;
    state.mayor = data.mayor;
    state.agreement = data.agreement;
    if (data.socials) state.socials = data.socials;
  },
  socialControlResults(state, data) {
    state.socialControlResults = data;
  },
  socialControlHome(state, data) {
    if (state.socialControlHome && state.socialControlHome.PaginateProjects) {
      state.socialControlHome.PaginateProjects.data = state.socialControlHome.PaginateProjects.data.concat(
        data.PaginateProjects.data
      );
    } else {
      state.socialControlHome = data;
    }
  },

  publicDiscussionsPage(state, data) {
    state.publicDiscussionsPage = data;
  },
  pdOpen(state, data) {
    state.pdOpen = data;
  },

  ic_about_page(state, data) {
    state.ic_about_page = data.about_project;
    state.ic_categories = data.categories;
    state.mayor = data.mayor;
    state.agreement = data.agreement;
    if (data.socials) state.socials = data.socials;
  },

  maps_page(state, data) {
    state.mapsPage = data;
    state.mayor = data.mayor;
    state.agreement = data.agreement;
    if (data.socials) state.socials = data.socials;
  },
  maps_one_page(state, data) {
    state.mapsOnePage = data;
    state.mayor = data.mayor;
    state.agreement = data.agreement;
    if (data.socials) state.socials = data.socials;
  },
  user_notifications(state, data) {
    state.userNotifications = data.notifications;
    state.mayor = data.mayor;
    state.agreement = data.agreement;
    if (data.socials) state.socials = data.socials;
  },
  user_appeals(state, data) {
    state.userAppeals = data.appeals;
    state.mayor = data.mayor;
    state.agreement = data.agreement;
    if (data.socials) state.socials = data.socials;
  },
  userScNotifications(state, data) {
    state.userScNotifications = data;
  },
  userDiscussions(state, data) {
    state.userDiscussions = data;
  },
  links_list(state, data) {
    state.links_list = data.useful_links || {};
  },
};
