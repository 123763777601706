import PD_ONE from "@/graphql/pd/OneDiscussion.graphql";

/**
 * Страница общественного обсуждения
 * @param state
 * @param commit
 * @param apollo
 * @returns {Promise<void>}
 * @constructor
 */
async function PD_OPEN({ commit }, apollo) {
  const token = localStorage.getItem("apollo-token");
  let { data } = await apollo.clients.pd.query({
    query: PD_ONE,
    context: {
      headers: {
        Authorization: "Bearer " + token,
      },
    },
  });
  if (data.OneActiveDiscussion) {
    commit("pdOpen", data.OneActiveDiscussion);
  }
}

export { PD_OPEN };
