import axios from "axios";

/**
 * Запрос на получение страницы Обратная связь
 * @param state
 * @param commit
 * @returns {Promise<void>}
 * @constructor
 */
async function GET_FEEDBACK_PAGE({ state, commit }) {
  await axios
    .get(state.api + "/api/feedback")
    .then(({ data }) => {
      commit("feedback_page", data);
    })
    .catch(({ response }) => {
      commit("feedback_page", {});
    });
}

/**
 * Запрос на получение страницы Официальное обращение
 * @param state
 * @param commit
 * @param token
 * @returns {Promise<void>}
 * @constructor
 */
async function GET_APPEAL_PAGE({ state, commit }, token) {
  await axios
    .get(state.api + "/api/officialappeals", {
      headers: {
        Authorization: "Bearer " + token,
      },
    })
    .then(({ data }) => {
      commit("appeal_page", data);
    })
    .catch(({ response }) => {
      commit("appeal_page", {});
    });
}

/**
 * Запрос на отправку формы официального обращения
 * @param state
 * @param data
 * @returns {Promise<AxiosResponse<any>>}
 * @constructor
 */
async function POST_OFFICIAL_APPEAL({ state }, data) {
  return await axios.post(state.api + "/api/postofficialappeals", data.data, {
    headers: {
      Authorization: "Bearer " + data.token,
    },
  });
}

/**
 * Запрос на получение страницы Прием корреспонденции и граждан
 * @param state
 * @param commit
 * @returns {Promise<void>}
 * @constructor
 */
async function GET_RECEPTION_PAGE({ state, commit }) {
  await axios
    .get(state.api + "/api/correspondenceandcitizens")
    .then(({ data }) => {
      commit("reception_page", data);
    })
    .catch(({ response }) => {
      commit("reception_page", {});
    });
}

/**
 * Запрос на получение страницы Единая дежурная диспетчерская служба
 * @param state
 * @param commit
 * @returns {Promise<void>}
 * @constructor
 */
async function GET_DD_SERVICE_PAGE({ state, commit }) {
  await axios
    .get(state.api + "/api/dutydispatchservices")
    .then(({ data }) => {
      commit("dd_service_page", data);
    })
    .catch(({ response }) => {
      commit("dd_service_page", {});
    });
}

export { GET_FEEDBACK_PAGE, GET_APPEAL_PAGE, POST_OFFICIAL_APPEAL, GET_RECEPTION_PAGE, GET_DD_SERVICE_PAGE };
