<template>
  <div :class="{ 'header__bottom-mobile-hide': headerBottomHide }" class="header__bottom header__bottom-with-nav">
    <h2 class="header__bottom-title">
      <router-link :to="{ name: 'sc' }"> Общественный контроль </router-link>
    </h2>
    <nav class="header__nav">
      <ul class="header__nav-list">
        <li v-for="(item, index) in navLinks" :key="index" class="header__nav-item">
          <router-link
            :to="{ name: item.route }"
            :class="{ active: isRouteActive(item.route) }"
            class="header__nav-link"
          >
            {{ item.title }}
          </router-link>
        </li>
      </ul>
    </nav>
  </div>
</template>

<script>
export default {
  name: "HeaderScBottomComponent",
  data() {
    return {
      navLinks: [
        {
          title: "Сообщить о проблеме",
          route: "sc_map",
        },
        {
          title: "О проекте",
          route: "sc_about",
        },
        {
          title: "Темы",
          route: "sc_themes",
        },
        {
          title: "Результаты",
          route: "sc_results",
        },
      ],
    };
  },
  methods: {
    isRouteActive(route) {
      return !!(
        this.$route.name === route ||
        (this.$route.meta &&
          this.$route.meta.parent &&
          this.$route.meta.parent.filter((p) => p.name === route).length)
      );
    },
  },
  computed: {
    headerBottomHide() {
      return ["sc_map"].includes(this.$route.name);
    },
  },
};
</script>
