<template>
  <div class="modal-links">
    <button class="modal__close" @click="$emit('close')">
      <CloseIcon />
    </button>
    <h2 class="modal-links__title">{{ getTitle() }}</h2>
    <form @submit.prevent="submitFilterForm" class="filter__form" autocomplete="off">
      <div class="search">
        <div class="search__input">
          <label for="news_search" class="search__label"> Введите слово для поиска </label>
          <div class="search__input__wrap">
            <input v-model="form.title.value" id="news_search" type="text" maxlength="255" />
            <button v-if="form.title.value" type="button" @click="resetFilterForm">
              <CloseIcon />
            </button>
          </div>
        </div>
        <div class="search__btn">
          <button type="submit" class="btn-blue_dark" :disabled="loading">
            <LoadingIndicator v-if="loading" title="Загрузка" />
            <template v-else>Найти</template>
          </button>
        </div>
      </div>
    </form>
    <div class="modal-links__wrap">
      <LoadingIndicator v-if="loading" />
      <template v-else-if="links && links.length">
        <ul class="modal-links__list">
          <li v-for="(item, i) in links" :key="i" class="modal-links__list-item">
            <a class="modal-links__item" :href="item.url | url" target="_blank">
              <span class="modal-links__item-title">{{ item.title }}</span>
              <span class="modal-links__item-link">{{ item.url }}</span>
            </a>
          </li>
        </ul>
        <PaginationComponent
          v-if="page !== null && total !== null && total > maxOnPage"
          :total="Math.ceil(total / maxOnPage)"
          @change="paginate"
          :page="page"
        />
      </template>
      <p v-else class="_item__text">{{ getResponseTitle() }}</p>
    </div>
  </div>
</template>

<script>
import CloseIcon from "components/svg/CloseIcon.vue";
import LoadingIndicator from "@/components/LoadingIndicator.vue";
import PaginationComponent from "@/components/Pagination.vue";
import axios from "axios";

export default {
  name: "LinksModal",
  props: {
    options: Object,
  },
  data() {
    return {
      page: 1,
      loading: false,
      form: {
        title: {
          value: null,
          message: null,
        },
      },
    };
  },
  created() {
    this.load();
  },
  computed: {
    links() {
      return this.$store.state.links_list?.data;
    },
    total() {
      return this.$store.state?.links_list.total;
    },
    maxOnPage() {
      return this.$store.state?.links_list.per_page;
    },
  },
  methods: {
    getTitle() {
      if (this.options && this.options.code) {
        switch (this.options.code) {
          case this.$store.state.type.CODE_MKALA_LINKS:
            return "Сайты Махачкалы";
          case this.$store.state.type.CODE_DAGESTAN_LINKS:
            return "Сайты Дагестана";
          case this.$store.state.type.CODE_RUSSIAN_LINKS:
            return "Сайты России";
          case this.$store.state.type.CODE_USEFUL_LINK:
            return "Полезные ссылки";
        }
      }
      return "Полезные ссылки";
    },
    async load(params = { data: { type_id: this.options.code } }) {
      if (!this.loading) {
        this.loading = true;
        let apiUrl = params.url || this.$store.state.api + "/api/usefullinks";
        await axios
          .post(apiUrl, params.data)
          .then(({ data }) => {
            this.loading = false;
            this.$store.state.links_list = data.useful_links || {};
          })
          .catch(() => {
            this.loading = false;
          });
      }
    },
    paginate(e) {
      this.page = e;
      let url = this.$store.state.links_list.first_page_url.slice(0, -1) + this.page;
      this.load({
        data: this.$store.state.filterData || { type_id: this.options.code },
        url: url,
      });
    },
    /**
     * Фильтр ссылок
     * @returns {Promise<void>}
     */
    async submitFilterForm() {
      if (!this.loading) {
        this.page = 1;
        let fd = {
          type_id: this.options.code,
          title: this.form.title.value,
        };
        this.$store.state.filterData = fd;
        await this.load({ data: fd });
      }
    },
    /**
     * Сброс фильтра
     */
    resetFilterForm() {
      Object.keys(this.form).forEach((key) => {
        this.form[key].value = null;
      });
      this.$store.state.filterData = null;
      this.paginate(1);
    },
    getResponseTitle() {
      if (!this.$store.state.filterData) {
        return "Ссылки отсутствуют";
      }
      if (this.$store.state.filterData.title) {
        return `По запросу "${this.$store.state.filterData.title}" ничего не найдено`;
      }
      return "По вашему запросу ничего не найдено";
    },
  },
  components: {
    CloseIcon,
    LoadingIndicator,
    PaginationComponent,
  },
};
</script>

<style lang="stylus">
.modal-links {
  width 95vw
  max-width 1190px
  overflow hidden
  padding 80px
  color var(--color_dark)
  background: var(--color_white);
  box-shadow: 0 30px 60px var(--color_black_o15);
  border-radius: var(--radius);
  +below(1200px) {
    max-width 960px
  }
  +below(768px) {
    max-width 576px
    padding 48px 40px 40px
  }
  +below(540px) {
    width auto
  }
  +below(420px) {
    padding 16px
  }

  &__title {
    font-weight: 700
    font-size: 1.875em
    line-height 1.2em
    margin-top 0
    +below(768px) {
      font-weight: 500;
      font-size: 1.125em
      line-height: 24px;
      margin-bottom: 24px;
    }
    +below(420px) {
      padding-right 40px
    }
  }

  &__wrap {
    margin-top: calc(var(--margin) * 2);
    min-height 20vh
    +below(560px) {
      margin-top: 24px;
    }
  }

  &__list {
    display grid

    &-item {
      border-top 1px solid var(--color_gray_divider)

      &:first-child {
        border-top none

        .modal-links__item {
          padding-top: 0;
        }
      }
    }
  }

  &__item {
    display grid
    grid-template-columns 720fr 294fr
    align-items center
    grid-gap var(--margin)
    padding 16px 0
    +below(768px) {
      display flex
      flex-direction column-reverse
      align-items flex-start
      gap 8px
    }
    +below(560px) {
      padding 8px 0
    }

    &-title {
      font-weight: 500;
      font-size: 1.125em
      line-height: 130%
      +below(768px) {
        font-size: 1em;
        line-height: 20px;
      }
    }

    &-link {
      font-size: 0.875em
      line-height: 20px;
      color: var(--main_color)
    }
  }
}
</style>
