<template>
  <i class="iconCron">
    <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M36.0559 43.8723C32.4389 43.8723 29.4929 46.8023 29.4929 50.4361C29.4929 54.0536 32.4225 56.9999 36.0559 56.9999C39.6729 56.9999 42.6189 54.0699 42.6189 50.4361C42.6189 46.8023 39.6893 43.8723 36.0559 43.8723ZM36.0559 53.7098C34.2393 53.7098 32.7827 52.2367 32.7827 50.4361C32.7827 48.6356 34.2555 47.1624 36.0559 47.1624C37.8727 47.1624 39.3293 48.6356 39.3293 50.4361C39.3293 52.2367 37.8727 53.7098 36.0559 53.7098Z"
        fill="black"
      />
      <path d="M56.2522 3H52.2097V37.3086H56.2522V3Z" fill="black" />
      <path d="M7.80695 3H3.7644V37.3086H7.80695V3Z" fill="black" />
      <path d="M32.0296 3H27.9871V37.3086H32.0296V3Z" fill="black" />
      <path d="M19.9185 20.1543H15.876V37.3085H19.9185V20.1543Z" fill="black" />
      <path d="M44.1409 20.1543H40.0984V37.3085H44.1409V20.1543Z" fill="black" />
      <path
        d="M8.87071 48.1118L11.1948 45.7874C8.64157 43.2339 4.48445 43.2339 1.9149 45.7874C-0.6383 48.3409 -0.6383 52.4986 1.9149 55.0684C4.46809 57.6219 8.62521 57.6219 11.1948 55.0684L8.87071 52.7441C7.59411 54.0208 5.51555 54.0208 4.23896 52.7441C2.96236 51.4673 2.96236 49.3886 4.23896 48.1118C5.51555 46.8351 7.57775 46.8351 8.87071 48.1118Z"
        fill="black"
      />
      <path
        d="M53.4697 43.8723C49.8527 43.8723 46.9067 46.8023 46.9067 50.4361V56.9999H50.18V50.4361C50.18 48.6192 51.653 47.1624 53.4534 47.1624C55.27 47.1624 56.7266 48.6356 56.7266 50.4361V56.9999H60V50.4361C60.0164 46.8023 57.0867 43.8723 53.4697 43.8723Z"
        fill="black"
      />
      <path
        d="M16.3831 50.4361V57H19.6564V50.4361C19.6564 48.6192 21.1294 47.1625 22.9297 47.1625H26.2031V43.8887H22.9297C19.3127 43.8724 16.3831 46.8024 16.3831 50.4361Z"
        fill="black"
      />
    </svg>
  </i>
</template>

<script>
export default {
  name: "LogoCron",
};
</script>

<style scoped></style>
