import CityIdeas from "@/graphql/ic/CityIdeas.graphql";
import OneIdea from "@/graphql/ic/OneIdea.graphql";
import IcAbout from "@/graphql/ic/IcAbout.graphql";

/**
 * Запрос на получение идей горожан
 * @param state
 * @param apollo
 * @param variables
 * @param loadingType
 * @returns {Promise<void>}
 * @constructor
 */
async function GET_CITY_IDEAS({ state }, { apollo, variables, loadingType }) {
  await apollo
    .query({
      query: CityIdeas,
      variables: variables,
    })
    .then(({ data }) => {
      state.ic_categories = data.categories;

      switch (loadingType) {
        case state.loading_types.DEFAULT:
        case state.loading_types.PAGINATION:
          {
            state.city_ideas = data.ideas_paginate;
          }
          break;
        case state.loading_types.LOAD_MORE:
          {
            state.city_ideas.data.push(...data.ideas_paginate.data);
            state.city_ideas.paginatorInfo = data.ideas_paginate.paginatorInfo;
          }
          break;
      }
    })
    .catch(() => {});
}

/**
 * Запрос на получение одной идеи
 * @param state
 * @param apollo
 * @param variables
 * @param token
 * @returns {Promise<void>}
 * @constructor
 */
async function GET_ONE_IDEA({ state }, { apollo, variables, token }) {
  await apollo
    .query({
      query: OneIdea,
      variables,
      context: {
        headers: {
          Authorization: "Bearer " + token,
        },
      },
    })
    .then(({ data }) => {
      state.one_idea = data.OneIdea;
    })
    .catch(() => {});
}

/**
 * Запрос на получение страницы Идеи горожан - о проекте
 * @param commit
 * @param apollo
 * @returns {Promise<void>}
 * @constructor
 */
async function GET_IC_ABOUT({ commit }, { apollo }) {
  await apollo
    .query({
      query: IcAbout,
    })
    .then(({ data }) => {
      commit("ic_about_page", data);
    })
    .catch(() => {
      commit("ic_about_page", {});
    });
}

export { GET_CITY_IDEAS, GET_ONE_IDEA, GET_IC_ABOUT };
