<template>
  <div class="header__bottom header__bottom-main">
    <nav class="header__nav header__nav-main">
      <ul class="header__nav-list">
        <li class="header__nav-item" :key="index" v-for="(item, index) in navigator">
          <router-link
            v-if="item.route"
            :to="{ name: item.route, params: item.params }"
            :class="{ active: isRouteActive(item.route) }"
            class="header__nav-link"
          >
            {{ item.title }}
          </router-link>
          <a v-else :href="item.url" target="_blank" class="header__nav-link">{{ item.title }}</a>
          <div v-if="item.child_routes && item.child_routes.length" class="header__dropdown">
            <div class="dropdown__mayor" v-if="item.route === 'mayor' && mayor">
              <div class="dropdown__mayor-left">
                <ul>
                  <li v-for="(r, i) in item.child_routes" :key="i" class="header__dropdown-item">
                    <router-link :to="{ name: r.route, params: r.params }">{{ r.title }}</router-link>
                  </li>
                </ul>
                <div class="dropdown__mayor-info">
                  <div class="dropdown__mayor-name">
                    {{ mayor.surname }} <br />
                    {{ mayor.name }} {{ mayor.patronymic }}
                  </div>
                  <div class="dropdown__mayor-description" v-if="mayor.description">
                    {{ mayor.description }}
                  </div>
                  <div class="d-mayor__socials-list">
                    <a class="d-mayor__socials-item" :href="'mailto:' + mayor.email">
                      <MailIcon />
                    </a>
                    <a
                      v-for="(soc, ind) in mayor.socials"
                      :key="ind"
                      :href="soc.link"
                      class="d-mayor__socials-item"
                      target="_blank"
                    >
                      <i class="icon-light" v-html="soc.icon"></i>
                    </a>
                  </div>
                </div>
              </div>
              <div class="dropdown__mayor-img">
                <img :src="mayor.head_img | image($store.state.api)" :alt="mayor.head_img | image_alt" />
              </div>
            </div>
            <ul v-else>
              <li v-for="(r, index) in item.child_routes" :key="index" class="header__dropdown-item">
                <router-link v-if="r.route" :to="{ name: r.route, params: r.params }">
                  {{ r.title }}
                </router-link>
                <a v-else :href="r.url" target="_blank">{{ r.title }}</a>
              </li>
            </ul>
          </div>
        </li>
      </ul>
    </nav>
    <!--    <div class="header__search-wrap">-->
    <!--      <a class="header__old-site" href="https://old.mkala.ru" title="Перейти на старый сайт" target="_blank">-->
    <!--        Старый сайт-->
    <!--      </a>-->
    <!--      <div class="header__search-input">-->
    <!--        <input type="text" placeholder="Поиск по сайту" />-->
    <!--      </div>-->
    <!--      <a class="header__search header__nav-link">-->
    <!--        <span class="header__search-text">Поиск по сайту</span>-->
    <!--        <SearchIcon class="icon" />-->
    <!--      </a>-->
    <!--    </div>-->
  </div>
</template>

<script>
import SearchIcon from "components/svg/SearchIcon.vue";
import MailIcon from "components/svg/MailIcon.vue";

export default {
  name: "HeaderMainBottomComponent",
  components: { MailIcon, SearchIcon },
  props: {
    mayor: Object,
    navigator: Array,
  },
  beforeMount() {
    this.correctDropdownList();
  },
  beforeUpdate() {
    this.correctDropdownList();
  },
  methods: {
    correctDropdownList() {
      let elemList = document.querySelectorAll(".header__dropdown");
      elemList.forEach((elem) => {
        const rect = elem.getBoundingClientRect();
        if (rect.right > document.documentElement.clientWidth) {
          elem.classList.add("header__dropdown-left");
        }
      });
    },
    isRouteActive(route) {
      return !!(
        this.$route.name === route ||
        (this.$route.meta &&
          this.$route.meta.parent &&
          this.$route.meta.parent.filter((p) => p.name === route).length)
      );
    },
  },
};
</script>
