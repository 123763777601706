import axios from "axios";

/**
 * Запрос на получение учреждений и организаций
 * @param state
 * @param commit
 * @param url
 * @returns {Promise<void>}
 * @constructor
 */
async function GET_ORGS_PAGE({ state, commit }, url) {
  let apiUrl = url ? url : state.api + "/api/searchdepartments";
  await axios
    .post(apiUrl, {})
    .then(({ data }) => {
      commit("orgs_page", data);
    })
    .catch(({ response }) => {
      commit("orgs_page", {});
    });
}

/**
 * Запрос на получение конкретной новости
 * @param state
 * @param commit
 * @param id
 * @returns {Promise<void>}
 * @constructor
 */
async function GET_OPEN_ORG({ state, commit }, id) {
  await axios
    .get(state.api + "/api/department/" + id)
    .then(({ data }) => {
      commit("org_open_page", data);
    })
    .catch(({ response }) => {
      commit("org_open_page", {});
    });
}

/**
 * Фильтр учреждений и организаций
 * @param state
 * @param commit
 * @param data
 * @returns {Promise<AxiosResponse<any>>}
 * @constructor
 */
async function POST_ORGS_FILTER({ state, commit }, data) {
  const url = data.url ? data.url : state.api + "/api/searchdepartments";
  return await axios
    .post(url, data.data)
    .then(({ data }) => {
      commit("orgs_page", data);
    })
    .catch(({ response }) => {
      commit("orgs_page", {});
    });
}

export { GET_ORGS_PAGE, GET_OPEN_ORG, POST_ORGS_FILTER };
