import axios from "axios";

/**
 * Запрос на получение Страницы деятельности
 * @param state
 * @param commit
 * @param data
 * @returns {Promise<void>}
 * @constructor
 */
async function GET_ACTIVITY_PAGE({ state, commit }, data) {
  let apiUrl = data && data.url ? data.url : state.api + "/api/activities";
  await axios
    .get(apiUrl)
    .then(({ data }) => {
      commit("activity_page", data);
    })
    .catch(({ data }) => {
      commit("activity_page", {});
    });
}

/**
 * Фильтр деятельности
 * @param state
 * @param commit
 * @param data
 * @param url
 * @returns {Promise<AxiosResponse<any>>}
 * @constructor
 */
async function POST_ACTIVITIES_FILTER({ state, commit }, { data, url }) {
  const apiUrl = url || state.api + "/api/searchactivities";
  try {
    data.append("type_id", state.type.CODE_ACTIVITY);
  } catch (e) {
    data.type_id = state.type.CODE_ACTIVITY;
  }
  return await axios
    .post(apiUrl, data)
    .then(({ data }) => {
      commit("activity_page", data);
    })
    .catch(({ response }) => {
      commit("activity_page", {});
    });
}

export { GET_ACTIVITY_PAGE, POST_ACTIVITIES_FILTER };
