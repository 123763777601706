import axios from "axios";
import SC_MAP_PAGE from "@/graphql/sc/map.graphql";
import SC_MAP_PAGE_NO_AUTH from "@/graphql/sc/mapnoauth.graphql";
import SC_RESULTS_PAGE from "@/graphql/sc/Results.graphql";
import SC_HOME_PAGE from "@/graphql/sc/HomePage.graphql";

/**
 * Страница карты общественного контроля для авторизованных
 * @param state
 * @param commit
 * @param apollo
 * @returns {Promise<void>}
 * @constructor
 */
async function SC_PAGE({ commit }, apollo) {
  const token = localStorage.getItem("apollo-token");
  let { data } = await apollo.clients.sc.mutate({
    mutation: token ? SC_MAP_PAGE : SC_MAP_PAGE_NO_AUTH,
    context: {
      headers: {
        Authorization: "Bearer " + token,
      },
    },
  });
  if (data.AllClaimCategories) {
    commit("socialControlCategories", data.AllClaimCategories);
  }
  if (data.AllClaimStatuses) {
    commit("socialControlStatuses", data.AllClaimStatuses);
  }
  if (data.AllClaims) {
    commit("socialControlMarkers", data.AllClaims);
  }
}

/**
 * Запрос на получение страницы Общественный контроль - о проекте
 * @param state
 * @param commit
 * @returns {Promise<void>}
 * @constructor
 */
async function GET_SC_ABOUT({ state, commit }) {
  await axios
    .get(state.api + "/api/scaboutproject")
    .then(({ data }) => {
      commit("sc_about_page", data);
    })
    .catch(({ response }) => {
      commit("sc_about_page", {});
    });
}

/**
 * Запрос на получение страницы Общественный контроль - темы
 * @param state
 * @param commit
 * @returns {Promise<void>}
 * @constructor
 */
async function GET_SC_THEMES({ state, commit }) {
  await axios
    .get(state.api + "/api/sccategories")
    .then(({ data }) => {
      commit("sc_themes", data);
    })
    .catch(({ response }) => {
      commit("sc_themes", {});
    });
}

/**
 * Запрос на получение страницы Общественный контроль - результаты
 * @param state
 * @param commit
 * @param apollo
 * @returns {Promise<void>}
 * @constructor
 */
async function SC_RESULTS({ commit }, apollo) {
  let { data } = await apollo.clients.sc.query({
    query: SC_RESULTS_PAGE,
  });
  if (data.PaginateProjects) {
    commit("socialControlResults", data.PaginateProjects);
  }
}

/**
 * Запрос на получение страницы Общественный контроль
 * @param state
 * @param commit
 * @param apollo
 * @returns {Promise<void>}
 * @constructor
 */
async function SC_HOME({ commit }, apollo) {
  let { data } = await apollo.clients.sc.query({
    query: SC_HOME_PAGE,
  });
  commit("socialControlHome", data);
}

/**
 * Запрос чтобы отметить уведомления как прочитанные
 * @param state
 * @param data
 * @returns {Promise<AxiosResponse<any>>}
 * @constructor
 */
async function POST_MARK_AS_READ({ state }, data) {
  return await axios.post(state.api + "/api/notificationmarkasread", data.data, {
    headers: {
      Authorization: "Bearer " + data.token,
    },
  });
}

export { SC_PAGE, GET_SC_ABOUT, GET_SC_THEMES, SC_RESULTS, SC_HOME, POST_MARK_AS_READ };
