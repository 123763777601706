import axios from "axios";

function hasImg(store, item) {
  return item.head_img && item.head_img.img && item.head_img.img.url;
}

/**
 * Запрос на кэширование адресов
 * @param state
 * @param data
 * @returns {Promise<>}
 * @constructor
 */
async function CACHE_ADDRESSES({ state }, data) {
  return await axios.post(state.api + "/api/postaddaddress", data);
}

export { hasImg, CACHE_ADDRESSES };
