<template>
  <i class="icon-big">
    <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M50.6668 10.667H13.3335C11.2118 10.667 9.17693 11.5098 7.67664 13.0101C6.17635 14.5104 5.3335 16.5453 5.3335 18.667V45.3337C5.3335 47.4554 6.17635 49.4902 7.67664 50.9905C9.17693 52.4908 11.2118 53.3337 13.3335 53.3337H50.6668C52.7886 53.3337 54.8234 52.4908 56.3237 50.9905C57.824 49.4902 58.6668 47.4554 58.6668 45.3337V18.667C58.6668 16.5453 57.824 14.5104 56.3237 13.0101C54.8234 11.5098 52.7886 10.667 50.6668 10.667ZM49.5735 16.0003L33.8935 31.6803C33.6456 31.9303 33.3507 32.1287 33.0257 32.264C32.7007 32.3994 32.3522 32.4691 32.0002 32.4691C31.6481 32.4691 31.2996 32.3994 30.9746 32.264C30.6497 32.1287 30.3547 31.9303 30.1068 31.6803L14.4268 16.0003H49.5735ZM53.3335 45.3337C53.3335 46.0409 53.0525 46.7192 52.5525 47.2193C52.0524 47.7194 51.3741 48.0003 50.6668 48.0003H13.3335C12.6263 48.0003 11.948 47.7194 11.4479 47.2193C10.9478 46.7192 10.6668 46.0409 10.6668 45.3337V19.7603L26.3468 35.4403C27.8468 36.9385 29.8802 37.78 32.0002 37.78C34.1202 37.78 36.1535 36.9385 37.6535 35.4403L53.3335 19.7603V45.3337Z"
        fill="white"
      />
    </svg>
  </i>
</template>

<script>
export default {
  name: "MailIcon",
};
</script>
