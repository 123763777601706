<template>
  <div id="app">
    <vue-progress-bar />
    <HeaderComponent />
    <Transition name="page" mode="out-in">
      <RouterView :key="$route.fullPath" />
    </Transition>
    <FooterComponent />
    <ModalComponent />
    <notifications position="top center"></notifications>
  </div>
</template>

<script>
import HeaderComponent from "./components/HeaderComponent.vue";
import FooterComponent from "./components/FooterComponent.vue";
import ModalComponent from "./components/ModalComponent.vue";

export default {
  created() {
    this.$Progress.start();
    this.$router.beforeEach((to, from, next) => {
      if (to.meta.progress !== undefined) {
        const meta = to.meta.progress;
        this.$Progress.parseMeta(meta);
      }
      this.$Progress.start();
      next();
    });
    this.$router.afterEach(() => {
      this.$Progress.finish();
    });
  },
  mounted() {
    this.$Progress.finish();
  },
  metaInfo() {
    return this.$seo();
  },
  components: {
    ModalComponent,
    FooterComponent,
    HeaderComponent,
  },
};
</script>

<style lang="stylus">
@import "styles/style.styl"
.scroll-top {
  fixed right 64px bottom 64px
  border: 1px solid var(--main_color);
  box-sizing: border-box;
  width 50px
  height 50px
  border-radius 100%
  background var(--main_color)
  cursor pointer
  display: flex;
  align-items: center;
  justify-content: center;
  transition .2s
  z-index 10
  +below(768px) {
    display none
  }

  svg path {
    transition .2s
    fill var(--color_white)
  }

  &:focus,
  &:hover {
    border-color var(--color_dark)
    background var(--color_dark)

    svg path {
      fill: var(--color_white)
    }
  }
}
</style>
