import axios from "axios";

/**
 * Запрос на получение страницы Структура
 * @param state
 * @param commit
 * @returns {Promise<void>}
 * @constructor
 */
async function GET_STRUCTURE_PAGE({ state, commit }) {
  await axios
    .get(state.api + "/api/structure")
    .then(({ data }) => {
      commit("structure_page", data);
    })
    .catch(({ response }) => {
      commit("structure_page", {});
    });
}

/**
 * Фильтр на странице Структура
 * @param state
 * @param commit
 * @param data
 * @returns {Promise<AxiosResponse<any>>}
 * @constructor
 */
async function GET_STRUCTURE_MINISTERS({ state }, data) {
  return await axios.get(state.api + "/api/ministers", {
    params: data,
  });
}

export { GET_STRUCTURE_PAGE, GET_STRUCTURE_MINISTERS };
