import axios from "axios";

/**
 * Запрос на получение органов власти
 * @param state
 * @param commit
 * @param data
 * @returns {Promise<void>}
 * @constructor
 */
async function GET_GOVERNMENT_PAGE({ state, commit }, data) {
  await axios
    .get(state.api + "/api/government", {
      params: data,
    })
    .then(({ data }) => {
      commit("government_page", data);
    })
    .catch(() => {
      commit("government_page", {});
    });
}

export { GET_GOVERNMENT_PAGE };
