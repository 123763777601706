import axios from "axios";

/**
 * Получение страницы О городе
 * @param state
 * @param commit
 * @param nextPageUrl
 * @returns {Promise<void>}
 * @constructor
 */
async function GET_MYCITY_PAGE({ state, commit }) {
  await axios
    .get(state.api + "/api/mycity")
    .then(({ data }) => {
      commit("mycity_page", data);
    })
    .catch(({ response }) => {
      commit("mycity_page", {});
    });
}

/**
 * Получение страницы Исторический очерк
 * @param state
 * @param commit
 * @param nextPageUrl
 * @returns {Promise<void>}
 * @constructor
 */
async function GET_MYCITY_HISTORY({ state, commit }) {
  await axios
    .get(state.api + "/api/historicalsketch")
    .then(({ data }) => {
      commit("mycity_history", data);
    })
    .catch(({ response }) => {
      commit("mycity_history", {});
    });
}

/**
 * Получение СМИ
 * @param state
 * @param commit
 * @param nextPageUrl
 * @returns {Promise<void>}
 * @constructor
 */
async function GET_MYCITY_SMI_PAGE({ state, commit }) {
  await axios
    .get(state.api + "/api/smi")
    .then(({ data }) => {
      commit("smi_page", data);
    })
    .catch(({ response }) => {
      commit("smi_page", {});
    });
}

/**
 * Получение справочных данных
 * @param state
 * @param commit
 * @param nextPageUrl
 * @returns {Promise<void>}
 * @constructor
 */
async function GET_MYCITY_REF_PAGE({ state, commit }) {
  await axios
    .get(state.api + "/api/referencedata")
    .then(({ data }) => {
      commit("mycity_ref", data);
    })
    .catch(({ response }) => {
      commit("mycity_ref", {});
    });
}

/**
 * Запрос на получение страницы медиа
 * @param state
 * @param commit
 * @returns {Promise<void>}
 * @constructor
 */
async function GET_MYCITY_MEDIA_PAGE({ state, commit }) {
  await axios
    .get(state.api + "/api/mycitygalleries")
    .then(({ data }) => {
      commit("mycity_media_page", data);
    })
    .catch(({ response }) => {
      commit("mycity_media_page", {});
    });
}

/**
 * Запрос на получение конкретной медиа
 * @param state
 * @param commit
 * @param id
 * @returns {Promise<void>}
 * @constructor
 */
async function GET_MYCITY_MEDIA_OPEN({ state, commit }, id) {
  await axios
    .get(state.api + "/api/mycitygallery/" + id)
    .then(({ data }) => {
      commit("mycity_media_open", data);
    })
    .catch(({ response }) => {
      commit("mycity_media_open", {});
    });
}

/**
 * Запрос на фильтр медиа
 * @param state
 * @param data
 * @returns {Promise<AxiosResponse<any>>}
 * @constructor
 */
async function POST_MYCITY_MEDIA_FILTER({ state }, data) {
  const url = data.url ? data.url : state.api + "/api/searchmycitygalleries";
  return await axios.post(url, data.data);
}

export {
  GET_MYCITY_SMI_PAGE,
  GET_MYCITY_PAGE,
  GET_MYCITY_HISTORY,
  GET_MYCITY_REF_PAGE,
  GET_MYCITY_MEDIA_PAGE,
  GET_MYCITY_MEDIA_OPEN,
  POST_MYCITY_MEDIA_FILTER,
};
