import Vue from "vue";
import Router from "vue-router";

Vue.use(Router);

export default function createRouter() {
  const routes = [
    {
      path: "/",
      name: "home",
      component: () => import("./views/home/index.vue"),
    },
    {
      path: "/news",
      name: "news",
      component: () => import("./views/news/list.vue"),
    },
    {
      path: "/news/:id/:url",
      name: "news_item",
      component: () => import("./views/news/open.vue"),
      meta: {
        parent: [
          {
            title: "Новости",
            name: "news",
          },
        ],
      },
    },
    {
      path: "/messages/:id/:url",
      name: "messages_item",
      component: () => import("./views/messages/open.vue"),
      meta: {
        parent: [
          {
            title: "Сообщения",
            name: "news",
          },
        ],
      },
    },
    {
      path: "/mycity/about",
      name: "mycity",
      component: () => import("./views/mycity/about.vue"),
    },
    {
      path: "/mycity/history",
      name: "mycity_history",
      component: () => import("./views/mycity/history.vue"),
    },
    {
      path: "/mycity/posters",
      name: "posters",
      component: () => import("./views/mycity/poster/list.vue"),
    },
    {
      path: "/mycity/poster/:id/:url",
      name: "poster_item",
      component: () => import("./views/mycity/poster/open.vue"),
      meta: {
        parent: [
          {
            title: "Главная",
            name: "home",
          },
        ],
      },
    },
    {
      path: "/mycity/media",
      name: "media",
      component: () => import("./views/mycity/media/list.vue"),
    },
    {
      path: "/mycity/media/:id",
      name: "media_item",
      component: () => import("./views/mycity/media/open.vue"),
      meta: {
        parent: [
          {
            title: "Медиа",
            name: "media",
          },
        ],
      },
    },
    {
      path: "/mycity/smi",
      name: "mycity_smi",
      component: () => import("./views/mycity/smi/index.vue"),
    },
    {
      path: "/mycity/reference",
      name: "mycity_reference",
      component: () => import("./views/mycity/references/index.vue"),
    },
    {
      path: "/documents",
      name: "documents",
      component: () => import("./views/documents/list.vue"),
    },
    {
      path: "/servicescatalog",
      name: "services_catalog",
      component: () => import("./views/services/services_catalog/index.vue"),
    },
    {
      path: "/services/:id",
      name: "service",
      component: () => import("./views/services/service_open/index.vue"),
      meta: {
        parent: [
          {
            title: "Услуги",
            name: "services_catalog",
          },
        ],
      },
    },
    {
      path: "/servicecenters",
      name: "service_centers",
      component: () => import("./views/services/service_centers/index.vue"),
      meta: {
        parent: [
          {
            title: "Услуги",
            name: "services_catalog",
          },
        ],
      },
    },
    {
      path: "/services/:id/faq",
      name: "services_faq",
      component: () => import("./views/services/services_faq/index.vue"),
      meta: {
        parent: [
          {
            title: "Название услуги",
            name: "service",
          },
        ],
      },
    },
    {
      path: "/servicesabout",
      name: "service_about",
      component: () => import("./views/services/services_municipal/index.vue"),
    },
    {
      path: "/servicesreports",
      name: "services_reports",
      component: () => import("./views/services/services_reports/list.vue"),
      meta: {
        parent: [
          {
            title: "Услуги",
            name: "services_catalog",
          },
        ],
      },
    },
    {
      path: "/faq",
      name: "faq",
      component: () => import("./views/services/faq/index.vue"),
    },
    {
      path: "/mayor",
      name: "mayor",
      component: () => import("./views/mayor/mayor_biography/index.vue"),
    },
    {
      path: "/mayoractivity",
      name: "mayor_activity",
      component: () => import("./views/mayor/mayor_activity/list.vue"),
      meta: {
        parent: [
          {
            title: "Глава города",
            name: "mayor",
          },
        ],
      },
    },
    {
      path: "/mayormedia",
      name: "mayor_media",
      component: () => import("./views/mayor/mayor_media/list.vue"),
      meta: {
        parent: [
          {
            title: "Глава города",
            name: "mayor",
          },
        ],
      },
    },
    {
      path: "/mayormedia/:id",
      name: "mayor_media_item",
      component: () => import("./views/mayor/mayor_media/open.vue"),
      meta: {
        parent: [
          {
            title: "Медиа",
            name: "mayor_media",
          },
        ],
      },
    },
    {
      path: "/structure",
      name: "localStructure",
      component: () => import("./views/local/structure.vue"),
      meta: {
        parent: [
          {
            title: "Местное самоуправление",
            name: "local",
          },
        ],
      },
    },
    {
      path: "/orgs",
      name: "localOrgs",
      component: () => import("./views/local/orgs.vue"),
      meta: {
        parent: [
          {
            title: "Местное самоуправление",
            name: "local",
          },
        ],
      },
    },
    {
      path: "/orgs/:id",
      name: "localOrganization",
      component: () => import("./views/local/organization.vue"),
      meta: {
        parent: [
          {
            title: "Местное самоуправление",
            name: "local",
          },
        ],
      },
    },
    {
      path: "/ministers/:id",
      name: "localMinister",
      component: () => import("./views/local/minister.vue"),
      meta: {
        parent: [
          {
            title: "Местное самоуправление",
            name: "local",
          },
        ],
      },
    },
    {
      path: "/governments",
      name: "localGovernments",
      component: () => import("./views/local/governments.vue"),
      meta: {
        parent: [
          {
            title: "Местное самоуправление",
            name: "local",
          },
        ],
      },
    },
    {
      path: "/projects",
      name: "projects",
      component: () => import("./views/local/projects/list.vue"),
      meta: {
        parent: [
          {
            title: "Местное самоуправление",
            name: "local",
          },
        ],
      },
    },
    {
      path: "/activity",
      name: "localActivity",
      component: () => import("./views/local/activity.vue"),
      meta: {
        parent: [
          {
            title: "Местное самоуправление",
            name: "local",
          },
        ],
      },
    },
    {
      path: "/feedback",
      name: "feedback",
      component: () => import("./views/feedback/index.vue"),
    },
    {
      path: "/officialappeal",
      name: "official_appeal",
      component: () => import("./views/feedback/appeal.vue"),
      meta: {
        requiresAuth: true,
        parent: [
          {
            title: "Обратная связь",
            name: "feedback",
          },
        ],
      },
    },
    {
      path: "/reception",
      name: "feedback_reception",
      component: () => import("./views/feedback/reception.vue"),
      meta: {
        parent: [
          {
            title: "Обратная связь",
            name: "feedback",
          },
        ],
      },
    },
    {
      path: "/dutyservice",
      name: "feedback_dutyservice",
      component: () => import("./views/feedback/dutyService.vue"),
      meta: {
        parent: [
          {
            title: "Обратная связь",
            name: "feedback",
          },
        ],
      },
    },
    {
      path: "/socialcontrol",
      name: "sc",
      component: () => import("./views/sc/index.vue"),
      meta: {
        parent: [
          {
            title: "Обратная связь",
            name: "feedback",
          },
        ],
      },
    },
    {
      path: "/socialcontrolmap",
      name: "sc_map",
      component: () => import("./views/sc/map.vue"),
    },
    {
      path: "/socialcontrolabout",
      name: "sc_about",
      component: () => import("./views/sc/about.vue"),
      meta: {
        parent: [
          {
            title: "Обратная связь",
            name: "feedback",
          },
          {
            title: "Общественный контроль",
            name: "sc",
          },
        ],
      },
    },
    {
      path: "/socialcontrolthemes",
      name: "sc_themes",
      component: () => import("./views/sc/themes.vue"),
      meta: {
        parent: [
          {
            title: "Обратная связь",
            name: "feedback",
          },
          {
            title: "Общественный контроль",
            name: "sc",
          },
        ],
      },
    },
    {
      path: "/socialcontrolresults",
      name: "sc_results",
      component: () => import("./views/sc/results.vue"),
      meta: {
        parent: [
          {
            title: "Обратная связь",
            name: "feedback",
          },
          {
            title: "Общественный контроль",
            name: "sc",
          },
        ],
      },
    },
    {
      path: "/publicdiscussions",
      name: "pd",
      component: () => import("./views/pd/list.vue"),
      meta: {
        parent: [
          {
            title: "Обратная связь",
            name: "feedback",
          },
        ],
      },
    },
    {
      path: "/publicdiscussions/:id",
      name: "pd_open",
      component: () => import("./views/pd/open.vue"),
      meta: {
        parent: [
          {
            title: "Обратная связь",
            name: "feedback",
          },
          {
            title: "Общественные обсуждения",
            name: "pd",
          },
        ],
      },
    },
    {
      path: "/cityideas",
      name: "ic",
      component: () => import("./views/ic/index.vue"),
      meta: {
        parent: [
          {
            title: "Обратная связь",
            name: "feedback",
          },
        ],
      },
    },
    {
      path: "/allcityideas",
      name: "ic_all",
      component: () => import("./views/ic/all.vue"),
      meta: {
        parent: [
          {
            title: "Обратная связь",
            name: "feedback",
          },
          {
            title: "Идеи горожан",
            name: "ic",
          },
        ],
      },
    },
    {
      path: "/realizedcityideas",
      name: "ic_realized",
      component: () => import("./views/ic/realized.vue"),
      meta: {
        parent: [
          {
            title: "Обратная связь",
            name: "feedback",
          },
          {
            title: "Идеи горожан",
            name: "ic",
          },
        ],
      },
    },
    {
      path: "/cityideasabout",
      name: "ic_about",
      component: () => import("./views/ic/about.vue"),
      meta: {
        parent: [
          {
            title: "Обратная связь",
            name: "feedback",
          },
          {
            title: "Идеи горожан",
            name: "ic",
          },
        ],
      },
    },
    {
      path: "/profile",
      name: "profile",
      component: () => import("./views/profile/index.vue"),
    },
    {
      path: "/maps",
      name: "maps",
      component: () => import("./views/maps/index.vue"),
    },
    {
      path: "/maps/:id",
      name: "map_open",
      component: () => import("./views/maps/open.vue"),
      meta: {
        parent: [
          {
            title: "Галерея карт",
            name: "maps",
          },
        ],
      },
    },
    {
      path: "/tourism/:link",
      name: "tourism",
      component: () => import("./views/tourism/index.vue"),
    },
    {
      path: "/tourism/:link/open/:id/:url",
      name: "tourism_open",
      component: () => import("./views/tourism/open.vue"),
    },
    {
      path: "/sitemap",
      name: "sitemap",
      component: () => import("./views/site_map/index.vue"),
    },
    {
      path: "/notready",
      name: "notready",
      component: () => import("./views/PageNotReady.vue"),
    },
    {
      path: "*",
      name: "notfound",
      component: () => import("./views/PageNotFound.vue"),
    },
  ];

  return new Router({
    mode: "history",
    fallback: true,
    scrollBehavior(to) {
      if (to.hash) {
        return { selector: to.hash };
      } else {
        return {
          x: 0,
          y: 0,
        };
      }
    },
    routes,
  });
}
