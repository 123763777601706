import axios from "axios";

/**
 * Запрос на получение списка новостей
 * @param state
 * @param commit
 * @returns {Promise<void>}
 * @constructor
 */
async function GET_POSTERS_PAGE({ state, commit }) {
  await axios
    .get(state.api + "/api/posters")
    .then(({ data }) => {
      commit("posters_page", data);
    })
    .catch(({ response }) => {
      commit("posters_page", {});
    });
}

async function GET_OPEN_POSTER({ state, commit }, id) {
  await axios
    .get(state.api + "/api/poster/" + id)
    .then(({ data }) => {
      commit("posters_open_page", data);
    })
    .catch(({ response }) => {
      commit("posters_open_page", {});
    });
}

async function POST_POSTERS_FILTER({ state }, data) {
  const url = data.url ? data.url : state.api + "/api/searchposters";
  return await axios.post(url, data.data);
}

/**
 * Запрос на фильтр новостей
 * @param state
 * @param data
 * @returns {Promise<AxiosResponse<any>>}
 * @constructor
 */
async function POST_POSTER_FILTER({ state }, data) {
  const url = data.url ? data.url : state.api + "/api/searchposters";
  return await axios.post(url, data.data);
}

/**
 * Запрос на поиск новости
 * @param state
 * @param data
 * @returns {Promise<AxiosResponse<any>>}
 * @constructor
 */

/**
 * Запрос на получение конкретной новости
 * @param state
 * @param commit
 * @returns {Promise<void>}
 * @constructor
 */

export { GET_POSTERS_PAGE, GET_OPEN_POSTER, POST_POSTERS_FILTER, POST_POSTER_FILTER };
