<template>
  <figure class="icon pagination-left-icon">
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7.0001 12.9998L14.5901 12.9998L11.2901 16.2898C11.1018 16.4781 10.996 16.7335 10.996 16.9998C10.996 17.2661 11.1018 17.5215 11.2901 17.7098C11.4784 17.8981 11.7338 18.0039 12.0001 18.0039C12.2664 18.0039 12.5218 17.8981 12.7101 17.7098L17.7101 12.7098C17.8011 12.6147 17.8725 12.5026 17.9201 12.3798C18.0201 12.1364 18.0201 11.8633 17.9201 11.6198C17.8725 11.4971 17.8011 11.3849 17.7101 11.2898L12.7101 6.28982C12.6171 6.19609 12.5065 6.12169 12.3847 6.07092C12.2628 6.02015 12.1321 5.99402 12.0001 5.99402C11.8681 5.99402 11.7374 6.02015 11.6155 6.07092C11.4937 6.12169 11.3831 6.19609 11.2901 6.28982C11.1964 6.38278 11.122 6.49338 11.0712 6.61524C11.0204 6.7371 10.9943 6.8678 10.9943 6.99982C10.9943 7.13183 11.0204 7.26253 11.0712 7.38439C11.122 7.50625 11.1964 7.61685 11.2901 7.70981L14.5901 10.9998L7.0001 10.9998C6.73489 10.9998 6.48053 11.1052 6.293 11.2927C6.10546 11.4802 6.0001 11.7346 6.0001 11.9998C6.0001 12.265 6.10546 12.5194 6.293 12.7069C6.48053 12.8945 6.73489 12.9998 7.0001 12.9998Z"
        fill="#A7A8AB"
      ></path>
    </svg>
  </figure>
</template>

<script>
export default {
  name: "PaginationRightIcon",
};
</script>
