function getMarkerIcon (svg, color_code) {
  return {
    layout: "default#image",
    imageHref: "data:image/svg+xml;base64," + btoa(getMarkerIconSvg(svg, color_code)),
    imageSize: [28, 28],
    imageOffset: [-21, -21],
  };
}

function getMarkerIconSvg (svg, color_code) {
  let tmp = document.createElement("div");
  tmp.innerHTML =
    "<svg width=\"42\" height=\"42\" viewBox=\"0 0 42 42\" fill=\"none\" xmlns=\"http://www.w3.org/2000/svg\">" +
    "<circle cx=\"21\" cy=\"21\" r=\"21\"/>" +
    "</svg>";
  let svgTmp = document.createElement("div");
  svgTmp.innerHTML = svg;
  let svgColor = getMarkerSvgColor(color_code);
  let svgWithFill = svgTmp.querySelector("path[fill], rect[fill]");
  let svgWithStroke = svgTmp.querySelector("path[stroke], path[stroke]");
  let svgWithStrokeAndFill = svgTmp.querySelector("rect[stroke][fill], path[stroke][fill]");
  if (svgWithFill) svgWithFill.setAttribute("style", "fill:" + svgColor);
  if (svgWithStroke) svgWithStroke.setAttribute("style", "stroke:" + svgColor);
  if (svgWithStrokeAndFill) svgWithStrokeAndFill.setAttribute("style", "stroke:" + svgColor);
  const base64 = "data:image/svg+xml;base64," + btoa(svgTmp.innerHTML);
  let image = document.createElementNS("http://www.w3.org/2000/svg", "image");
  image.setAttributeNS(null, "x", 8);
  image.setAttributeNS(null, "y", 8);
  image.setAttributeNS(null, "width", 26);
  image.setAttributeNS(null, "height", 26);
  image.setAttributeNS(null, "href", base64);
  tmp.querySelector("svg").appendChild(image);
  tmp.querySelector("svg circle").setAttributeNS(null, "fill", getMarkerColor(color_code));
  return tmp.innerHTML;
}

function getMarkerColor (code) {
  switch (code) {
    case 1:
      return "#D6B902";
    case 2:
      return "#DA2127";
    case 3:
      return "#43B85F";
    case 4:
      return "#19C1F2";
  }
}

function getMarkerSvgColor (code) {
  switch (code) {
    case 1:
      return "white";
    case 2:
      return "white";
    case 3:
      return "white";
    case 4:
      return "white";
  }
}

function socialControlMarkers (state) {
  return (statuses, categories) => {
    let markers = state.socialControl.markers
      .filter((m) => m.category)
      .filter((m) => m.status.code !== 5)
      .map((m) => {
        m.color = getMarkerColor(m.status.code);
        m.icon = getMarkerIcon(m.category.parent.icon, m.status.code);
        m.iconSvg = getMarkerIconSvg(m.category.parent.icon, m.status.code);
        m.iconSvgRaw = m.category.parent.icon;
        m.coords = [m.x, m.y];
        return m;
      });
    // if (statuses.length) {
    markers = markers.filter((m) => statuses.includes(m.status.code));
    // }
    // if (categories.length) {
    markers = markers.filter((m) => categories.includes(m.category.parent.id));
    // }
    return markers;
  };
}

function socialControlStatuses (state) {
  return state.socialControl.statuses.filter((s) => s.code !== 5);
}

function socialControlCategories (state) {
  return state.socialControl.categories.filter((c) => c.icon && c.icon.includes("svg"));
}

export { socialControlMarkers, socialControlStatuses, socialControlCategories };
