<template>
  <i class="icon">
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7.02571 12.9999C7.02406 11.8139 7.25715 10.6393 7.71157 9.54386C8.16598 8.44838 8.83272 7.45367 9.67336 6.61707C10.514 5.78047 11.5119 5.11852 12.6096 4.66938C13.7072 4.22025 14.8829 3.99281 16.0689 4.00017C21.0176 4.03696 24.9759 8.15034 24.9759 13.1129V13.9999C24.9759 18.4769 25.9126 21.0748 26.7376 22.4947C26.8264 22.6464 26.8737 22.8189 26.8747 22.9947C26.8756 23.1705 26.8302 23.3435 26.7429 23.4962C26.6557 23.6488 26.5297 23.7758 26.3778 23.8642C26.2258 23.9527 26.0532 23.9995 25.8774 23.9999H6.12329C5.94744 23.9995 5.77482 23.9526 5.62284 23.8642C5.47087 23.7757 5.34491 23.6487 5.25767 23.496C5.17044 23.3434 5.12502 23.1704 5.12599 22.9945C5.12696 22.8187 5.17429 22.6462 5.26321 22.4945C6.08862 21.0745 7.02571 18.4766 7.02571 13.9999L7.02571 12.9999Z"
        stroke="white"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M12 24V25C12 26.0609 12.4214 27.0783 13.1716 27.8284C13.9217 28.5786 14.9391 29 16 29C17.0609 29 18.0783 28.5786 18.8284 27.8284C19.5786 27.0783 20 26.0609 20 25V24"
        stroke="white"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  </i>
</template>

<script>
export default {
  name: "BellIcon",
};
</script>
