<template>
  <div class="pd-mod">
    <div class="pd-mod__header">
      <span>Предложить идею</span>
      <button type="button" @click="$emit('close')" class="pd-mod__close" v-if="!loading">
        <CloseIcon />
      </button>
    </div>
    <div class="pd-mod__body">
      <form
        v-if="!isFormSent"
        @submit.prevent="submitForm"
        class="pd-mod__form ic-mod__form"
        autocomplete="off"
      >
        <div class="pd-mod__form-item">
          <label for="fio" class="pd-mod__form-label">
            <span class="required__symbol">*</span> Инициатор:
          </label>
          <input
            type="text"
            v-model="form.author.value"
            id="fio"
            placeholder="ФИО"
            :readonly="form.author.value"
          />
          <span class="pd-mod__error" v-if="form.author.messages.length">
            {{ form.author.messages[0] }}
          </span>
        </div>
        <div class="pd-mod__form-item pd-mod__form-checkbox ic-mod__form-checkbox">
          <label class="label-small">
            <input type="checkbox" v-model="form.show_author.value" />
            <span>Показать на сайте мою фамилию и имя как инициатора идеи</span>
          </label>
          <span class="pd-mod__error" v-if="form.show_author.messages.length">
            {{ form.show_author.messages[0] }}
          </span>
        </div>
        <div class="pd-mod__form-item">
          <label for="title" class="pd-mod__form-label"> Название идеи </label>
          <input type="text" v-model="form.title.value" id="title" placeholder="Название" />
          <span class="pd-mod__error" v-if="form.title.messages.length">
            {{ form.title.messages[0] }}
          </span>
        </div>
        <div class="pd-mod__form-item">
          <label class="pd-mod__form-label"> <span class="required__symbol">*</span> Выберите тему </label>
          <Multiselect
            track-by="id"
            label="title"
            v-model="form.category_id.value"
            :options="categories"
            placeholder="Тема идеи"
            selectLabel="Выбрать ↵"
            deselectLabel="Удалить ↵"
            selectedLabel="Выбрано"
            :searchable="true"
            :allow-empty="true"
          >
            <span slot="noOptions">Список пуст</span>
            <span slot="noResult">Ничего не найдено.</span>
          </Multiselect>
          <span class="pd-mod__error" v-if="form.category_id.messages.length">
            {{ form.category_id.messages[0] }}
          </span>
        </div>
        <div class="pd-mod__form-item">
          <label for="remark_text" class="pd-mod__form-label">
            <span class="required__symbol">*</span> Краткое описание идеи
          </label>
          <textarea
            v-model="form.text.value"
            id="remark_text"
            rows="10"
            placeholder="Краткое описание вашей идеи"
          ></textarea>
          <span class="pd-mod__error" v-if="form.text.messages.length">
            {{ form.text.messages[0] }}
          </span>
        </div>
        <div class="pd-mod__form-item">
          <label class="pd-mod__form-label"> Загрузите фотографию </label>
          <LoadFilesComponent
            type="input"
            accept="image/*"
            :multiple="false"
            placeholder="Загрузите фото"
            @load="loadImg"
          />
          <span class="pd-mod__error" v-if="form.img.messages.length">
            {{ form.img.messages[0] }}
          </span>
        </div>
        <div class="pd-mod__form-item">
          <label class="pd-mod__form-label"> Загрузите документ или архив с файлами </label>
          <LoadFilesComponent type="input" @load="loadFiles" />
          <span class="pd-mod__error" v-if="form.documents.messages.length">
            {{ form.documents.messages[0] }}
          </span>
        </div>
        <div class="pd-mod__form-item">
          <label for="address" class="pd-mod__form-label"> Точный адрес или место на карте </label>
          <input
            type="text"
            v-model="form.address.value"
            id="address"
            placeholder="Адрес для реализации идеи"
            @input="loadLocationTimeout"
          />
          <span class="pd-mod__error" v-if="form.address.messages.length">
            {{ form.address.messages[0] }}
          </span>
        </div>
        <div class="pd-mod__form-item">
          <yandex-map :settings="settings" :coords="coords" v-if="mapReady" zoom="16">
            <ymap-marker marker-id="0" :coords="coords" :icon="icon" />
          </yandex-map>
        </div>
        <div class="pd-mod__form-item pd-mod__form-checkbox">
          <label>
            <input type="checkbox" v-model="form.agree_with_rules.value" />
            <span> Я ознакомлен(а) с <a class="link__blue" href="#">правилами</a> отправки сообщений </span>
          </label>
          <span class="pd-mod__error" v-if="form.agree_with_rules.messages.length">
            {{ form.agree_with_rules.messages[0] }}
          </span>
        </div>
        <button class="btn-blue btn--small" type="submit" :disabled="loading">
          <LoadingIndicator v-if="loading" title="Загрузка" />
          <template v-else>Отправить</template>
        </button>
      </form>
      <template v-else>
        <p class="pd-mod__answer-text ic-mod__answer-text">Спасибо вам за предложенную идею.</p>
        <button @click="close" class="btn-blue btn--small">Закрыть</button>
      </template>
    </div>
  </div>
</template>

<script>
import CloseIcon from "components/svg/CloseIcon.vue";
import LoadingIndicator from "@/components/LoadingIndicator.vue";
import LoadFilesComponent from "@/components/LoadFilesComponent.vue";
import UPLOAD_FILE from "@/graphql/UploadFile.graphql";
import IC_CREATE from "@/graphql/ic/IdeaTownspeopleCreate.graphql";
import Multiselect from "vue-multiselect";
import { loadYmap, yandexMap, ymapMarker } from "vue-yandex-maps";

export default {
  name: "IdeaSendModal",
  props: {
    options: Object,
  },
  data() {
    return {
      showPassword: false,
      loading: false,
      error: false,
      dropdownActive: false,
      isFormSent: false,
      mapReady: false,
      coords: [0, 0],
      settings: {
        apiKey: "2accbdff-2038-4811-9f12-f3799d64a50c",
        lang: "ru_RU",
        coordorder: "latlong",
        version: "2.1",
      },
      icon: {
        layout: "default#imageWithContent",
        imageHref: "/public/images/map-red-location.png",
        imageSize: [32, 36],
        imageOffset: [-18, -18],
      },
      form: {
        author: {
          value: null,
          messages: [],
          required: true,
          type: "string",
        },
        title: {
          value: null,
          messages: [],
          required: true,
          type: "string",
        },
        category_id: {
          value: null,
          messages: [],
          required: true,
          type: "object",
        },
        text: {
          value: null,
          messages: [],
          required: true,
          type: "string",
        },
        img: {
          value: null,
          messages: [],
          required: true, //false
          type: "array",
        },
        documents: {
          value: null,
          messages: [],
          required: false,
          type: "array",
        },
        address: {
          value: null,
          messages: [],
          required: true,
          type: "string",
        },
        show_author: {
          value: null,
          messages: [],
          required: false,
          type: "bool",
        },
        agree_with_rules: {
          value: null,
          messages: [],
          required: true,
          type: "bool",
        },
      },
      uploadFiles: [],
      uploadImg: {},
    };
  },
  beforeMount() {
    const user = this.options.user;
    this.form.author.value = `${user.surname ?? ""} ${user.name ?? ""}`;
  },
  mounted() {
    loadYmap({
      ...this.settings,
    }).then(() => {
      window.ymaps.ready(() => {
        window.ymaps.geocode("Россия, Республика Дагестан, Махачкала").then((res) => {
          this.coords = res.geoObjects.get(0).geometry.getCoordinates();
          this.mapReady = true;
        });
      });
    });
  },
  computed: {
    categories() {
      return this.$store.state.ic_categories || [];
    },
  },
  methods: {
    loadLocationTimeout() {
      if (this.form.address.value.length) {
        clearTimeout(this.timeout);
        this.timeout = setTimeout(() => {
          this.loadLocation();
        }, 1000);
      }
    },
    loadLocation() {
      loadYmap({
        ...this.settings,
      }).then(() => {
        window.ymaps.ready(() => {
          window.ymaps.geocode(this.form.address.value).then((res) => {
            this.coords = res.geoObjects.get(0).geometry.getCoordinates();
          });
        });
      });
    },
    loadImg(files) {
      this.form.img.value = files && files.length ? files : null;
    },
    loadFiles(files) {
      this.form.documents.value = files && files.length ? files : null;
    },
    checkForm() {
      // Очистка
      Object.keys(this.form).forEach((key) => {
        this.form[key].messages = [];
      });
      // Проверка
      Object.keys(this.form).forEach((key) => {
        if (!["file", "bool", "number", "array"].includes(this.form[key].type)) {
          if (this.form[key].required && (this.form[key].value === null || this.form[key].value === "")) {
            this.form[key].messages.push("Поле обязательно для заполнения");
          }
        }
        if (this.form[key].type === "bool") {
          if (this.form[key].required && !this.form[key].value) {
            this.form[key].messages.push("Вы должны нажать на галочку");
          }
        }
        if (this.form[key].type === "number") {
          if (this.form[key].required && !this.form[key].value) {
            this.form[key].messages.push("Выберите один из вариантов");
          }
        }
        if (this.form[key].type === "array") {
          if (this.form[key].required && !this.form[key].value) {
            this.form[key].messages.push("Добавьте хотя бы один документ");
          }
        }
      });
      // Подсчет количества ошибок
      let errorsLength = 0;
      Object.keys(this.form).forEach((key) => {
        errorsLength += this.form[key].messages.length;
      });
      return errorsLength === 0;
    },
    uploadFile() {
      let files = [];
      if (this.form.documents.value) files.push(...this.form.documents.value);
      if (this.form.img.value) files.push(this.form.img.value[0]);
      return new Promise((resolve) => {
        this.$apollo.provider.clients.ic
          .mutate({
            mutation: UPLOAD_FILE,
            variables: {
              files,
              folder: "ic",
            },
            context: {
              headers: {
                Authorization: "Bearer " + localStorage.getItem("apollo-token"),
              },
            },
          })
          .then(({ data }) => {
            if (data.UploadFile && data.UploadFile.length) {
              this.uploadFiles = data.UploadFile;
              this.uploadFiles.forEach((item, index) => {
                delete this.uploadFiles[index].__typename;
              });
              this.uploadImg = this.form.img.value ? this.uploadFiles.pop() : {};
              delete this.uploadImg.type;
            }
            resolve();
          });
      });
    },
    submitForm() {
      if (!this.loading) {
        if (this.checkForm()) {
          this.loading = true;
          this.uploadFile().then(() => {
            this.$apollo.provider.clients.ic
              .mutate({
                mutation: IC_CREATE,
                variables: {
                  category_id: this.form.category_id?.value.id,
                  title: this.form.title.value,
                  text: this.form.text.value,
                  address: this.form.address.value,
                  img: this.uploadImg,
                  documents: this.uploadFiles,
                  show_author: this.form.show_author.value,
                  agree_with_rules: this.form.agree_with_rules.value,
                },
                context: {
                  headers: {
                    Authorization: "Bearer " + localStorage.getItem("apollo-token"),
                  },
                },
              })
              .then(() => {
                this.loading = false;
                this.close();
                this.reload();
                // this.isFormSent = true;
                // setTimeout(() => {
                //   this.reload();
                // }, 2500);
              })
              .catch(({ graphQLErrors }) => {
                this.loading = false;
                graphQLErrors.forEach((err) => {
                  if (err.extensions.category === "validation") {
                    Object.keys(err.extensions.validation).forEach((key) => {
                      err.extensions.validation[key].forEach((message) => {
                        if (this.form[key]) {
                          this.form[key].messages.push(message);
                        }
                      });
                    });
                  }
                });
              });
          });
        }
      }
    },
    close() {
      this.$emit("close");
    },
    reload() {
      location.reload();
    },
  },
  components: {
    LoadingIndicator,
    CloseIcon,
    LoadFilesComponent,
    Multiselect,
    yandexMap,
    ymapMarker,
  },
};
</script>

<style lang="stylus">
@import '~vue-multiselect/dist/vue-multiselect.min.css'
@import "~@/styles/parts/pd.styl"
</style>
