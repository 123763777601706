import Vue from "vue";
import Vuex from "vuex";
import getters from "./getters/index";
import actions from "./actions/index";
import mutations from "./mutations";

Vue.use(Vuex);
export default function createStore() {
  return new Vuex.Store({
    getters: getters,
    actions: actions,
    mutations: mutations,
    state() {
      return {
        api: process.env.API || "http://localhost:8000",
        _modals: [],
        showSideNav: false, // адаптивное меню
        agreement: null,
        home_page: {},
        news_page: {},
        news_open_page: {},
        news_types: [],
        newsTypeCode: null, // фильтр по определенному типу новостей
        news_actual: [],
        main_categories: [],
        posters_page: {},
        posters_open_page: {},
        documents_page: {},
        departments: [],
        services_page: {},
        sc_page: {}, // services centers page
        sr_page: {}, // services reports page
        service: {},
        services_about_page: {},
        banners: {},
        faq_page: [],
        mayor: {},
        mayor_page: {},
        mayor_news_page: {},
        mayor_media_page: {},
        mayor_media_open: {},
        smi_page: {},
        mycity_page: {},
        mycity_history: {},
        mycity_ref: {},
        mycity_media_page: {},
        mycity_media_open: {},
        projects: {},
        orgs_page: {},
        org_open_page: {},
        minister_open_page: {},
        government_page: {},
        structure_page: {},
        activity_page: {},
        feedback_page: {},
        appeal_page: {},
        reception_page: {},
        dd_service_page: {},
        sc_about_page: {},
        sc_categories: [],
        socialControlResults: {},
        socialControlHome: {},
        publicDiscussionsPage: {},
        ic_about_page: {},
        city_ideas: {},
        one_idea: {},
        ic_categories: [],
        mapsPage: {},
        mapsOnePage: {},
        pdOpen: {},
        userAppeals: {},
        userNotifications: {},
        userScNotifications: {},
        userDiscussions: {},
        links_list: {},
        filterData: null, // данные, которые отправляем в запросе на фильтр
        filterResponse: null, // данные, которые получаем после запроса на фильтр
        filterList: [], // список элементов, полученных после запроса
        socials: [], // соц. сети мэра
        type: {
          CODE_MESSAGES: 1, // Сообщения
          CODE_MAYOR_NEWS: 2, // Новости мэра
          CODE_CITY_NEWS: 3, // Новости города
          CODE_REGION_NEWS: 4, // Новости региона
          CODE_REPORT_NEWS: 5, // Отчеты
          CODE_PROJECTS_NEWS: 6, // Проекты
          CODE_ACTIVITY: 7, // Деятельность
          CODE_PHOTO_GALLERY: 3,
          CODE_VIDEO_GALLERY: 4,
          CODE_DEPARTMENTS: 1, // Управление
          CODE_INSTITUTIONS: 2, // Учреждение и организации
          CODE_MAYOR: 1, // Глава города
          CODE_DEPUTY_HEADS: 2, // Заместители главы города
          CODE_ASSISTANT_HEADS: 3, // Помощник главы города
          CODE_HINTERLAND_HEADS: 4, // Главы внутригородских районов
          CODE_INDIVIDUAL: 1, // Тип Физическое лицо
          CODE_ENTITY: 2, // Тип Юридическое лицо
          CODE_PD_POSITIVE: 1, // голос "за"
          CODE_PD_NEGATIVE: 2, // голос "против"
          CODE_PD_AVOID: 3, // голос "воздержался"
          CODE_RUSSIAN_LINKS: 1, // сайты России
          CODE_DAGESTAN_LINKS: 2, // сайты Дагестана
          CODE_MKALA_LINKS: 3, // сайты Махачкалы
          CODE_USEFUL_LINK: 4, // полезные ссылки
          CODE_YEAR: 1, // Charts
          CODE_SQUARE: 2, // Charts
          CODE_PIE: 3, // Charts
          CODE_COLUMN: 4, // Charts
          CODE_BUBBLE: 5, // Charts
          CODE_TOP: 1, // Navigator
          CODE_BOTTOM: 2, // Navigator
          CODE_NAVIGATOR_SERVICES: 11, // Navigator
          CODE_NAVIGATOR_CLIENTS: 12, // Navigator
          CODE_NAVIGATOR_SYSTEM: 20, // Navigator
          CODE_NAVIGATOR_NOT_SYSTEM: 21, // Navigator
        },
        loading_types: {
          DEFAULT: 1,
          PAGINATION: 2,
          LOAD_MORE: 3,
        },
        status: {
          CODE_ACTIVE: 1,
          CODE_NOACTIVE: 2,
          CODE_NOT_PUBLISHED: 3,
          CODE_PD_NOTIFICATION: 51, // оповещение
          CODE_PD_DISCUSSION: 52, // обсуждение
          CODE_PD_FINISH: 53, // заключение
        },
        homeFullPage: undefined,
        socialControl: {
          markers: [],
          statuses: [],
          categories: [],
        },
        user: null,
        tourism: {},
        tourism_open: {},
      };
    },
  });
}
