<template>
  <header class="header">
    <div class="container">
      <div class="header__inner">
        <div class="header__top">
          <a @click.prevent="changeSideNav" class="header__menu-btn" href="#">
            <MenuIcon />
          </a>
          <router-link :to="{ name: 'home' }" class="header__logo" title="Главная страница">
            <Logo />
            <span>mkala.ru</span>
          </router-link>
          <h1 class="header__title" v-if="$route.name === 'home'">
            Официальный сайт администрации города Махачкалы
          </h1>
          <div class="header__title" v-else>Официальный сайт администрации города Махачкалы</div>
          <div class="header__user header__user--auth" @click.prevent v-if="user">
            <BellIcon />
            <span class="header__user-text">{{ getUser(user) }}</span>
            <div class="header__user-profile">
              <AccountIcon />
            </div>
            <div class="header__drop">
              <router-link :to="{ name: 'profile' }">
                <AccountIcon />
                <span>Личный кабинет</span>
              </router-link>
              <a href="#" @click.prevent="logout">
                <LogoutIcon />
                <span>Выйти</span>
              </a>
            </div>
          </div>
          <a class="header__user" @click.prevent="showLoginModal" v-else>
            <span class="header__user-text">Войти</span>
            <UserIcon />
          </a>
        </div>
        <HeaderScBottomComponent v-if="isSocialControl" />
        <HeaderPdBottomComponent v-else-if="isPublicDiscussion" />
        <HeaderIcBottomComponent v-else-if="isCityIdeas" />
        <HeaderMainBottomComponent v-else :mayor="mayor" :navigator="navigator" />
        <HeaderSideNavComponent :mayor="mayor" :navigator="navigator" />
      </div>
    </div>
  </header>
</template>

<script>
import Logo from "components/svg/Logo.vue";
import UserIcon from "components/svg/UserIcon.vue";
import HeaderMainBottomComponent from "components/HeaderMainBottom.vue";
import HeaderScBottomComponent from "components/HeaderScBottom.vue";
import HeaderPdBottomComponent from "components/HeaderPdBottom.vue";
import HeaderIcBottomComponent from "components/HeaderIcBottom.vue";
import LoginModal from "components/modals/LoginModal.vue";
import BellIcon from "components/svg/BellIcon.vue";
import AccountIcon from "components/svg/AccountIcon.vue";
import LogoutIcon from "components/svg/LogoutIcon.vue";
import MenuIcon from "components/svg/MenuIcon.vue";
import HeaderSideNavComponent from "components/HeaderSideNav.vue";

export default {
  name: "HeaderComponent",
  data() {
    return {
      navigator: [
        {
          title: "Глава города",
          route: "mayor",
          child_routes: [
            {
              title: "Деятельность",
              route: "mayor_activity",
            },
            {
              title: "Биография",
              route: "mayor",
            },
            {
              title: "Медиа",
              route: "mayor_media",
            },
          ],
        },
        {
          title: "Местное самоуправление",
          route: "localStructure",
          child_routes: [
            {
              title: "Структура",
              route: "localStructure",
            },
            {
              title: "Органы власти",
              route: "localGovernments",
            },
            {
              title: "Учреждения и организации",
              route: "localOrgs",
            },
            {
              title: "Деятельность",
              route: "localActivity",
            },
            {
              title: "Проекты",
              route: "projects",
            },
          ],
        },
        {
          title: "Документы",
          route: "documents",
        },
        {
          title: "Услуги",
          route: "services_catalog",
          child_routes: [
            {
              title: "Каталог услуг",
              route: "services_catalog",
            },
            {
              title: "Центры предоставления услуг",
              route: "service_centers",
            },
            {
              title: "Отчеты и методические материалы ",
              route: "services_reports",
            },
            {
              title: "Вопросы и ответы",
              route: "faq",
            },
            {
              title: "О муниципальных услугах",
              route: "service_about",
            },
          ],
        },
        {
          title: "Мой город",
          route: "mycity",
          child_routes: [
            {
              title: "Общие сведения",
              route: "mycity",
            },
            {
              title: "Исторический очерк",
              route: "mycity_history",
            },
            {
              title: "Афиша",
              route: "posters",
            },
            {
              title: "Медиа",
              route: "media",
            },
            {
              title: "Городские СМИ",
              route: "mycity_smi",
            },
            {
              title: "Справочные данные",
              route: "mycity_reference",
            },
          ],
        },
        {
          title: "Туризм",
          url: "https://turmkala.ru",
          params: {
            link: "routes",
          },
        },
        {
          title: "Карта",
          route: "maps",
          child_routes: [
            {
              title: "Геоинформационный портал",
              url: "https://gis.mkala.ru",
            },
            {
              title: "Галерея карт",
              route: "maps",
            },
          ],
        },
        {
          title: "Обратная связь",
          route: "feedback",
          child_routes: [
            {
              title: "Единое окно обратной связи",
              route: "feedback",
            },
            {
              title: "Направить официальное обращение",
              route: "official_appeal",
            },
            {
              title: "Прием корреспонденции и граждан",
              route: "feedback_reception",
            },
            {
              title: "Единая дежурная диспетчерская служба",
              route: "feedback_dutyservice",
            },
            {
              title: "Контакты",
              route: "feedback",
            },
          ],
        },
      ],
    };
  },
  computed: {
    user() {
      return this.$store.state.user;
    },
    mayor() {
      return this.$store.state.mayor;
    },
    isSocialControl() {
      return ["sc", "sc_map", "sc_about", "sc_themes", "sc_results"].includes(this.$route.name);
    },
    isPublicDiscussion() {
      return ["pd", "pd_open"].includes(this.$route.name);
    },
    isCityIdeas() {
      return ["ic", "ic_about", "ic_all", "ic_realized"].includes(this.$route.name);
    },
    hasHeaderBottom() {
      return !["profile"].includes(this.$route.name);
    },
  },
  beforeMount() {
    const token = localStorage.getItem("apollo-token");
    if (token) {
      this.$store.dispatch("GET_USER", token);
    }
  },
  methods: {
    logout() {
      localStorage.removeItem("apollo-token");
      location.reload();
    },
    getUser(user) {
      let name = user.name ? user.name.substr(0, 1) + "." : "";
      let patronymic = user.patronymic ? user.patronymic.substr(0, 1) + "." : "";
      return `${user.surname} ${name} ${patronymic}`;
    },
    showLoginModal() {
      if (!this.user) {
        this.$store.state._modals = [
          {
            component: LoginModal,
          },
        ];
      }
    },
    changeSideNav() {
      if (this.$store.state.showSideNav) {
        document.body.classList.remove("no-scroll");
        this.$store.state.showSideNav = false;
      } else {
        document.body.classList.add("no-scroll");
        this.$store.state.showSideNav = true;
      }
    },
  },
  components: {
    LogoutIcon,
    AccountIcon,
    BellIcon,
    HeaderScBottomComponent,
    HeaderPdBottomComponent,
    HeaderIcBottomComponent,
    HeaderMainBottomComponent,
    HeaderSideNavComponent,
    Logo,
    UserIcon,
    MenuIcon,
  },
};
</script>

<style lang="stylus">
.header
  &__top
    display grid
    grid-template-columns auto 1fr auto
    align-items center
    color var(--color_white)
    background-color var(--color_blue_dark)
    grid-gap 24px
    position relative
    z-index 999
    +below(1180px) {
      grid-template-columns: auto auto auto;
      justify-content space-between
    }
    +below(420px) {
      position fixed
      top 0
      left 0
      right 0
    }

    & > *
      padding 16px 0
      +below(380px) {
        padding 12px 0
      }

      &:last-child
        padding-right 32px
        +below(1200px) {
          padding-right: 24px;
        }
        +below(380px) {
          padding-right: 16px;
        }

  &__title
    font-weight: 500;
    font-size: 1.125em
    line-height: 24px
    width 100%
    margin 0
    +below(1180px) {
      display none
    }

  &__menu-btn {
    display flex
    align-items center
    padding-left 24px
    +above(1181px) {
      display none
    }
    +below(380px) {
      padding-left 16px
    }
  }

  &__logo
    font-weight: 500;
    font-size: 1.125em
    line-height: 24px
    display flex
    align-items center
    color var(--color_white)
    gap 8px
    padding-left 32px
    +below(1200px) {
      padding-left: 24px
    }
    +below(768px) {
      padding-left: 0
    }
    +below(380px) {
      font-size: 1em
      line-height: 20px
    }

    .icon {
      +below(380px) {
        display none
      }
    }

    &:hover
      color var(--color_white)

      svg path
        fill var(--color_white)

  &__bottom
    display flex
    align-items center
    padding 0 32px
    justify-content: space-between;
    background-color var(--color_white)
    gap 24px
    +below(1200px) {
      padding 0 24px
    }
    +below(620px) {
      flex-direction column
      align-items flex-start
      gap 0
    }
    +below(420px) {
      margin-top 56px
    }
    +below(380px) {
      margin-top 48px
      padding-left 16px
      padding-right 16px
    }

    &-main {
      +below(1180px) {
        padding 0 !important
      }
      +below(380px) {
        background-color var(--color_gray_light)
        padding 10px 16px
      }
    }

    &-title {
      font-weight: 500;
      font-size: 1.375em;
      line-height: 23px;
      margin 0
      color: var(--color_black);
      padding 22px 0
      +below(768px) {
        font-size: 1.125em;
        padding 20px 0
      }
    }

    &.header__bottom-with-nav {
      .header__nav-list {
        overflow-x auto
      }
    }

    &.header__bottom-mobile-hide {
      +below(880px) {
        display none
      }
    }

  &__drop
    visibility hidden
    opacity 0
    background: var(--color_white);
    box-shadow: 1px 2px 5px var(--color_black_o25);
    border-radius: var(--radius);
    padding 8px 24px
    transform: translateY(90%);
    absolute right bottom
    min-width 295px
    transition .2s

    a
      display flex
      align-items center
      justify-content flex-start
      gap 8px
      font-weight: normal;
      font-size: 1em;
      line-height: 24px;
      color: var(--color_dark);
      padding 16px 0

      &:not(:last-child)
        border-bottom: 1px solid var(--color_gray_divider);

  &__user
    display flex
    align-items center
    font-weight 400
    font-size: 1em
    line-height: 24px
    color var(--color_white)
    gap 12px
    cursor pointer

    .icon
      width: 24px;
      height: 24px;

    &:hover .header__drop
      visibility visible
      opacity 1
      transform: translateY(100%);
      z-index: 999;

    &--auth {
      gap 32px

      > .icon {
        +below(768px) {
          display none
        }
        &:after {
          content ""
          width 1px
          height 36px
          background var(--color_gray)
          right: -16px;
          position: relative;
        }
      }

      .header__user-text {
        +below(768px) {
          display none
        }
      }
    }

    &-profile {
      +above(769px) {
        display none
      }
      svg path {
        fill  var(--color_white)
      }
    }

    &-text {
      +below(420px) {
        display none
      }
    }

  &__nav
    display flex
    flex-shrink 0
    max-width 100%

    &.header__nav-main {
      +below(1180px) {
        display none
      }
    }

  &__nav-list
    display flex
    gap 24px
    +below(1280px) {
      gap 16px
    }

    &::-webkit-scrollbar {
      display none
    }

  &__nav-item
    position relative
    display inline-flex
    flex-shrink 0

    &:hover > .header__dropdown
      visibility visible
      opacity 1
      pointer-events auto
      top 100%

  &__nav-link
    font-weight: 400;
    font-size: 1.125em
    line-height: 19px
    padding 28px 0
    color var(--color_black)
    display block
    +below(768px) {
      padding 16px 0
      font-weight: normal;
      font-size: 0.875em
      line-height: 20px;
    }

    &:hover
      color var(--color_blue)

  &__dropdown {
    opacity 0
    pointer-events none
    visibility hidden
    position absolute
    top 90%;
    left 0
    z-index 50
    padding 24px 32px
    background var(--color_white)
    box-shadow: 0 8px 18px -6px rgba(24, 39, 75, 0.12), 0 12px 42px -4px rgba(24, 39, 75, 0.12);
    border-top 1px solid var(--color_gray_light)
    color var(--color_dark)
    transform: translate(-20px, 0px);
    border-radius 0 0 var(--radius) var(--radius)
    transition 0.2s

    &-left {
      left auto
      right 0
      transform translate(0)
    }

    &-item {
      margin-bottom: 16px;

      &:last-child {
        margin-bottom: 0;
      }

      a {
        display inline-block
        width 100%
        white-space: nowrap;
      }
    }
  }

  &__search {
    display flex
    align-items center
    padding 0
    gap 12px

    &-text {
      +below(1430px) {
        display none
      }
    }

    .icon {
      svg {
        +below(768px) {
          width: 20px
          height: 20px
        }
      }
    }

    &-wrap {
      display flex
      align-items center
      gap var(--margin)
      +below(1280px) {
        gap 8px
      }
      +below(1180px) {
        width 100%
      }
      +below(380px) {
        padding 6px
      }
    }

    &-input {
      flex-grow 1
      +above(1181px) {
        display none
      }

      input {
        width 100%
        font-size 1em
        line-height normal
        border none
        outline none
        +below(768px) {
          font-size 0.875em
          line-height 20px
        }

        &:active
        &:focus {
          border-bottom 1px solid var(--color_black)
        }
      }
    }
  }

  &__old-site {
    padding 8px 10px
    background var(--color_gray_divider)
    color var(--color_dark)
    transition .2s
    margin-left auto
    +below(1280px) {
      padding 4px
    }

    &:hover {
      background var(--color_gray)
    }

    &.sidenav__old-site {
      margin-top var(--margin)
      display flex
      padding 8px 10px
      align-self flex-start
    }
  }

  &__sidenav {
    position fixed
    top 56px // высота хедера
    left 0
    right 0
    bottom 0
    z-index 100
    background var(--color_white)
    height calc(100vh - 56px)
    overflow-y scroll
    width 100%
    padding 24px
    transform translateX(-100%)
    transition .2s
    +above(1181px) {
      display none
    }
    +below(860px) {
      padding-bottom: 80px
    }
    +below(380px) {
      top 48px
      padding 16px 16px 80px
      height calc(100vh - 48px)
    }

    &-show {
      transform translateX(0)
    }

    &-inner {
      display flex
      flex-direction column
    }

    .sidenav {
      &__links-block {
        padding 16px 0
        border-top 1px solid var(--color_gray_divider)

        &.sidenav__block-mayor {
          display flex
          gap 24px
          +below(640px) {
            flex-direction column
          }
          +below(420px) {
            gap 0
            flex-direction column-reverse

            .sidenav__link-main {
              &:not(&-mobile) {
                display none
              }
            }
          }
        }

        & > * {
          flex 1
        }

        &:first-child {
          padding-top: 0;
          border-top none
        }
      }

      &__link-main {
        font-weight: 500;
        font-size: 1.125em
        line-height: 24px;

        &-blue {
          color var(--color_blue_dark)
        }

        &-mobile {
          +above(421px) {
            display none
          }
        }
      }

      &__links-childs {
        margin-top: 16px;
        padding-left 24px
        display flex
        flex-direction column
        gap 16px
        +below(420px) {
          margin-top: 12px;
          padding-left: 16px;
          gap 12px
          font-size: 0.875em
          line-height: 20px;
        }
      }

      &__links-child {
        font-weight: 500;
        line-height: 20px;
        color var(--main_color)
      }

      &__mayor {
        display flex
        align-items flex-end
        gap 16px
        +below(420px) {
          flex-direction column
          align-items flex-start
          gap 12px
        }

        &-img {
          width 144px
          height 192px
          flex-shrink 0
          +below(420px) {
            margin-left: 16px;
          }

          img {
            object-fit cover
            object-position center
            width 100%
            height 100%
            border-radius: var(--radius);
          }
        }

        &-info {
          display flex
          flex-direction column
          gap 8px
          color var(--color_dark)
          +below(420px) {
            margin-left: 16px;
          }
        }

        &-name {
          font-weight: 500;
          font-size: 1.125em
          line-height: 24px;
          +below(420px) {
            font-size 1em
            line-height 20px
          }
        }

        &-description {
          font-size: 0.875em
          line-height: 20px;
        }

        &-socials {
          display flex
          align-items center
          flex-wrap wrap
          gap 12px
        }

        &-social {
          display flex

          svg {
            width 32px
            height auto

            path {
              fill var(--main_border_color)
            }
          }
        }
      }
    }
  }

.dropdown {
  &__mayor {
    display flex

    &-left {
      display flex
      flex-direction column
      justify-content space-between
      margin-right 16px
    }

    &-info {
      margin-top: 32px;
    }

    &-name {
      font-weight: 500;
      font-size: 1.375em
      line-height: 1.25em
      margin-bottom: 4px;
      white-space nowrap
    }

    &-description {
      font-size: 0.75em
      line-height: 1.5em
      margin-bottom: 16px
    }

    &-img {
      margin -8px -8px -8px 0
      width 265px
      height 300px

      img {
        object-fit cover
        object-position center
        width 100%
        height 100%
        border-radius: var(--radius);
      }
    }

    .d-mayor__socials-list {
      margin -6px
    }

    .d-mayor__socials-item {
      padding 6px

      svg {
        width 16px
        height 16px

        path {
          fill var(--main_border_color)
          transition .2s
        }
      }

      &:hover {
        svg path {
          fill var(--color_blue)
        }
      }
    }
  }
}
</style>
