import axios from "axios";

/**
 * Запрос на получение проектов
 * @param state
 * @param commit
 * @returns {Promise<void>}
 * @constructor
 */
async function GET_PROJECTS({ state, commit }) {
  await axios
    .get(state.api + "/api/projects")
    .then(({ data }) => {
      commit("projects", data);
    })
    .catch(({ response }) => {
      commit("projects", {});
    });
}

export { GET_PROJECTS };
