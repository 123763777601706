<template>
  <div class="header__sidenav" :class="{ 'header__sidenav-show': show }">
    <div class="header__sidenav-inner">
      <ul class="sidenav__list">
        <li
          v-for="(item, index) in navigator"
          :key="index"
          :class="{ 'sidenav__block-mayor': item.route === 'mayor' && mayor }"
          class="sidenav__links-block"
        >
          <div class="sidenav__links-wrap">
            <template v-if="item.child_routes && item.child_routes.length">
              <div class="sidenav__link-main">{{ item.title }}</div>
              <ul class="sidenav__links-childs">
                <li v-for="(r, i) in item.child_routes" :key="i">
                  <router-link :to="{ name: r.route, params: r.params }" class="sidenav__links-child">
                    {{ r.title }}
                  </router-link>
                </li>
              </ul>
            </template>
            <router-link
              v-else
              :to="{ name: item.route, params: item.params }"
              class="sidenav__link-main sidenav__link-main-blue"
            >
              {{ item.title }}
            </router-link>
          </div>
          <div v-if="item.route === 'mayor' && mayor" class="sidenav__mayor">
            <div class="sidenav__link-main sidenav__link-main-mobile">{{ item.title }}</div>
            <div class="sidenav__mayor-img">
              <img :src="mayor.head_img | image($store.state.api)" :alt="mayor.head_img | image_alt" />
            </div>
            <div class="sidenav__mayor-info">
              <div class="sidenav__mayor-name">
                {{ mayor.surname }}
                {{ mayor.name }}
                {{ mayor.patronymic }}
              </div>
              <div v-if="mayor.description" class="sidenav__mayor-description">
                {{ mayor.description }}
              </div>
              <div class="sidenav__mayor-socials">
                <a :href="'mailto:' + mayor.email" class="sidenav__mayor-social">
                  <MailIcon />
                </a>
                <a
                  v-for="(soc, ind) in mayor.socials"
                  :key="ind"
                  :href="soc.link"
                  class="sidenav__mayor-social"
                  target="_blank"
                >
                  <i class="icon" v-html="soc.icon"></i>
                </a>
              </div>
            </div>
          </div>
        </li>
      </ul>
      <!--      <a-->
      <!--        class="header__old-site sidenav__old-site"-->
      <!--        href="https://old.mkala.ru"-->
      <!--        title="Перейти на старый сайт"-->
      <!--        target="_blank"-->
      <!--      >-->
      <!--        Старый сайт-->
      <!--      </a>-->
    </div>
  </div>
</template>

<script>
import MailIcon from "components/svg/MailIcon.vue";

export default {
  name: "HeaderSideNavComponent",
  props: {
    mayor: Object,
    navigator: Array,
  },
  watch: {
    "$route.name"() {
      this.closeSideNav();
    },
  },
  computed: {
    show() {
      return this.$store.state.showSideNav;
    },
  },
  methods: {
    closeSideNav() {
      document.body.classList.remove("no-scroll");
      this.$store.state.showSideNav = false;
    },
  },
  components: {
    MailIcon,
  },
};
</script>
