import * as activity from "./activity.js";
import * as def from "./default.js";
import * as documents from "./documents.js";
import * as feedback from "./feedback.js";
import * as global from "./global.js";
import * as government from "./government.js";
import * as home from "./home.js";
import * as mayor from "./mayor.js";
import * as ministers from "./ministers.js";
import * as mycity from "./mycity.js";
import * as news from "./news.js";
import * as orgs from "./orgs.js";
import * as poster from "./poster.js";
import * as projects from "./projects.js";
import * as services from "./services.js";
import * as structure from "./structure.js";
import * as socialControl from "./socialControl.js";
import * as publicDiscussions from "./publicDiscussions.js";
import * as profile from "./profile.js";
import * as maps from "./maps.js";
import * as cityIdeas from "./cityIdeas.js";

export default Object.assign(
  def,
  home,
  socialControl,
  publicDiscussions,
  profile,
  news,
  structure,
  poster,
  documents,
  services,
  mayor,
  mycity,
  orgs,
  projects,
  feedback,
  activity,
  government,
  ministers,
  global,
  maps,
  cityIdeas
);
