import axios from "axios";

/**
 * Запрос на получение страницы учреждения или организации
 * @param state
 * @param commit
 * @param id
 * @returns {Promise<void>}
 * @constructor
 */
async function GET_OPEN_MINISTER({ state, commit }, id) {
  await axios
    .get(state.api + "/api/minister/" + id)
    .then(({ data }) => {
      commit("minister_open_page", data);
    })
    .catch(({ response }) => {
      commit("minister_open_page", {});
    });
}

export { GET_OPEN_MINISTER };
