import axios from "axios";

/**
 * Получение документов
 * @param state
 * @param commit
 * @param nextPageUrl
 * @returns {Promise<void>}
 * @constructor
 */
async function GET_DOCUMENTS_PAGE({ state, commit }) {
  await axios
    .get(state.api + "/api/documents")
    .then(({ data }) => {
      commit("documents_page", data);
    })
    .catch(({ response }) => {
      commit("documents_page", {});
    });
}

/**
 * Запрос на фильтр документов
 * @param state
 * @param data
 * @returns {Promise<AxiosResponse<any>>}
 * @constructor
 */
async function POST_DOCUMENTS_FILTER({ state }, data) {
  const url = data.url ? data.url : state.api + "/api/searchdocuments";
  return await axios.post(url, data.data);
}

export { GET_DOCUMENTS_PAGE, POST_DOCUMENTS_FILTER };
