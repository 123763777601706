import Vue from "vue";
import "es6-promise/auto";
import createApp from "./app";

Vue.mixin({
  beforeRouteUpdate(to, from, next) {
    const { asyncData } = this.$options;
    if (asyncData) {
      asyncData({
        store: this.$store,
        route: to,
        apollo: this.$apollo.provider,
      })
        .then(next)
        .catch(next);
    } else {
      next();
    }
  },
});

const { app, router, store, apollo } = createApp();
const { location } = window;

if (window.__INITIAL_STATE__) {
  store.replaceState(window.__INITIAL_STATE__);
}

router.onReady(() => {
  router.beforeResolve((to, from, next) => {
    const matched = router.getMatchedComponents(to);
    const prevMatched = router.getMatchedComponents(from);
    let diffed = false;
    const activated = matched.filter((c, i) => {
      diffed = diffed || prevMatched[i] !== c;
      return diffed;
    });
    const asyncDataHooks = activated.map((c) => c.asyncData).filter((_) => _);

    if (!asyncDataHooks.length) {
      return next();
    }
    return Promise.all(asyncDataHooks.map((hook) => hook({ store, route: to, apollo })))
      .then(() => {
        next();
      })
      .catch(next);
  });
  app.$mount("#app");
});

if (location.protocol === "https:" && navigator.serviceWorker) {
  navigator.serviceWorker.register("/service-worker.js");
}
