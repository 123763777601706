import axios from "axios";

/**
 * Запрос на получение списка новостей
 * @param state
 * @param commit
 * @returns {Promise<void>}
 * @constructor
 */
async function GET_SERVICES_PAGE({ state, commit }) {
  await axios
    .get(state.api + "/api/services")
    .then(({ data }) => {
      commit("services_page", data);
    })
    .catch(() => {
      commit("services_page", {});
    });
}

/**
 * Запрос на получение данных страницы "Центр предоставления услуг"
 * @param state
 * @param commit
 * @returns {Promise<void>}
 * @constructor
 */
async function GET_SERVICE_CENTERS_PAGE({ state, commit }) {
  await axios
    .get(state.api + "/api/servicecenters")
    .then(({ data }) => {
      commit("sc_page", data);
    })
    .catch(() => {
      commit("sc_page", {});
    });
}

/**
 * Запрос на получение данных страницы "Центр предоставления услуг"
 * @param state
 * @param commit
 * @returns {Promise<void>}
 * @constructor
 */
async function GET_SERVICES_REPORTS_PAGE({ state, commit }) {
  await axios
    .get(state.api + "/api/servicesreports")
    .then(({ data }) => {
      commit("sr_page", data);
    })
    .catch(() => {
      commit("sr_page", {});
    });
}

/**
 * Запрос на получение конкретной новости
 * @param state
 * @param commit
 * @param id
 * @returns {Promise<void>}
 * @constructor
 */
async function GET_SERVICE({ state, commit }, id) {
  await axios
    .get(state.api + "/api/services/" + id)
    .then(({ data }) => {
      commit("service", data);
    })
    .catch(() => {
      commit("service", {});
    });
}

async function GET_FAQ_PAGE({ state, commit }) {
  await axios
    .get(state.api + "/api/faq")
    .then(({ data }) => {
      commit("faq_page", data);
    })
    .catch(({ response }) => {
      commit("faq_page", {});
    });
}
async function GET_SERVICES_ABOUT_PAGE({ state, commit }) {
  await axios
    .get(state.api + "/api/servicesabout")
    .then(({ data }) => {
      commit("services_about_page", data);
    })
    .catch(({ response }) => {
      commit("services_about_page", {});
    });
}

/**
 * Запрос на фильтр новостей
 * @param state
 * @param data
 * @returns {Promise<AxiosResponse<any>>}
 * @constructor
 */
async function POST_SERVICES_FILTER({ state }, data) {
  const url = data.url ? data.url : state.api + "/api/searchservices";
  return await axios.post(url, data.data);
}

export {
  GET_SERVICES_PAGE,
  GET_SERVICES_REPORTS_PAGE,
  GET_SERVICE_CENTERS_PAGE,
  GET_SERVICES_ABOUT_PAGE,
  GET_SERVICE,
  POST_SERVICES_FILTER,
  GET_FAQ_PAGE,
};
