<template>
  <div class="mod">
    <div class="mod__header">
      <span class="mod__title">Техническая поддержка</span>
      <div class="mod__header-buttons">
        <button
          v-if="!loading"
          @click="close"
          type="button"
          class="mod__close"
        >
          <CloseIcon />
        </button>
      </div>
    </div>
    <div class="mod__body">
      <form class="mod__form">
        <span class="mod__text">
          Если у вас возникли вопросы или сложности с работой портала mkala.ru, например, вы не смогли зайти в
          личный кабинет, не загружается страница — отправьте обращение в техническую поддержку.
        </span>
        <div style="display: grid; grid-gap: 8px">
          <span class="mod__text">Содержание обращения</span>
          <textarea
            v-model.trim="form.message.value"
            class="mod__text-field"
            placeholder="Сообщение"
            rows="10"
          ></textarea>
          <span class="mod__input-error" v-if="form.message.messages.length">
            {{ form.message.messages[0] }}
          </span>
        </div>
        <div style="display: grid; grid-gap: 8px">
          <span class="mod__text mod__text--small">
            Вы можете приложить изображение, более полно раскрывающее суть вашего обащения. Допустимые форматы
            файла: jpg, png. Размер файла не может превышать 5 МБ.
          </span>
          <LoadFilesComponent
            @load="loadFiles"
            :multiple="false"
            :accept="form.documents_arr.accept"
            btnTitle="Добавить файл"
          />
          <span class="mod__input-error" v-if="form.documents_arr.messages.length">
            {{ form.documents_arr.messages[0] }}
          </span>
        </div>
        <div class="mod__buttons">
          <button
            class="mod__buttons-accept"
            :class="{
              'mod__buttons-accept--loading': loading,
            }"
            style="margin-right: auto; width: 170px"
            type="button"
            @click="submitForm"
          >
            <LoadingIndicator v-if="loading" title="Загрузка" />
            <template v-else>Отправить</template>
          </button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import AlertModal from "components/modals/AlertModal.vue";
import CloseIcon from "components/svg/CloseIcon.vue";
import LoadingIndicator from "@/components/LoadingIndicator.vue";
import LoadFilesComponent from "@/components/LoadFilesComponent.vue";
import axios from "axios";

export default {
  name: "SupportModal",
  props: {
    options: Object,
  },
  data() {
    return {
      form: {
        message: {
          value: null,
          messages: [],
          required: true,
          type: "string",
        },
        documents_arr: {
          value: null,
          messages: [],
          required: false,
          accept: "image/*",
          type: "file",
        },
      },
      loading: false,
      fileName: null,
    };
  },
  methods: {
    loadFiles(files) {
      this.form.documents_arr.value = files;
    },
    checkForm() {
      // Очистка
      Object.keys(this.form).forEach((key) => {
        this.form[key].messages = [];
      });
      // Проверка
      Object.keys(this.form).forEach((key) => {
        if (!["file", "bool", "number", "array"].includes(this.form[key].type)) {
          if (this.form[key].required && (this.form[key].value === null || this.form[key].value === "")) {
            this.form[key].messages.push("Поле обязательно для заполнения");
          }
        }
        if (this.form[key].type === "file") {
          if (this.form[key].required && this.$refs[this.form[key].refName]) {
            let files = this.$refs[this.form[key].refName].$refs.input.files;
            if (!files.length) {
              this.form[key].messages.push("Выберите файл");
            } else {
              const regExp = /(\w+\/)/;
              const fileType = files[0].type.match(regExp)[0];
              const requiredTypes = this.form[key].accept.match(regExp);
              if (!requiredTypes.includes(fileType)) {
                requiredTypes.forEach((t) => {
                  switch (t) {
                    case "image/":
                      this.form[key].messages.push("Файл должен быть изображением");
                      break;
                    case "video/":
                      this.form[key].messages.push("Файл должен быть в формате видео");
                      break;
                    default:
                      this.form[key].messages.push("Неверный тип файла");
                      break;
                  }
                });
              }
            }
          }
        }
      });
      // Подсчет количества ошибок
      let errorsLength = 0;
      Object.keys(this.form).forEach((key) => {
        errorsLength += this.form[key].messages.length;
      });
      return errorsLength === 0;
    },
    async submitForm() {
      if (!this.loading) {
        if (this.checkForm()) {
          this.loading = true;
          let fd = new FormData();
          Object.keys(this.form).forEach((key) => {
            if (key === "documents_arr") {
              if (this.form[key].value && this.form[key].value.length) {
                this.form[key].value.forEach((item, i) => {
                  fd.append(`documents_arr[${i}][title]`, item.name);
                  fd.append(`documents_arr[${i}][document]`, item);
                  fd.append(`documents_arr[${i}][position]`, i);
                });
              }
            } else {
              if (this.form[key].value !== null) fd.append(key, this.form[key].value);
            }
          });
          await axios
            .post(this.$store.state.api + "/api/posttechsupport", fd, {
              headers: {
                Authorization: "Bearer " + localStorage.getItem("apollo-token"),
              },
            })
            .then(() => {
              this.loading = false;
              this.close();
              this.showAlertModal();
            })
            .catch(() => {
              this.loading = false;
            });
        }
      }
    },
    showAlertModal() {
      this.$store.state._modals.push({
        component: AlertModal,
        options: {
          title: "Техническая поддержка",
          text: "Ваше сообщение успешно отправлено. <br /> Спасибо. ",
        },
      });
    },
    close() {
      this.$emit("close");
    },
  },
  components: {
    LoadingIndicator,
    CloseIcon,
    LoadFilesComponent,
  },
};
</script>

<style lang="stylus">
@import "~@/styles/parts/mod.styl"
</style>
