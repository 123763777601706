<template>
  <div class="mod">
    <div class="mod__header">
      <Logo class="mod__icon" />
      <span class="mod__title">Оповещение</span>
      <div class="mod__header-buttons">
        <button class="mod__close" type="button" @click="close">
          <CloseIcon />
        </button>
      </div>
    </div>
    <div class="mod__body mod__body-alert">{{ options.message }}</div>
  </div>
</template>

<script>
import CloseIcon from "components/svg/CloseIcon.vue";
import Logo from "components/svg/Logo.vue";

export default {
  name: "AlertModal",
  props: {
    options: {
      type: Object,
    },
  },
  methods: {
    close() {
      this.$emit("close");
      this.options.callback();
    },
  },
  components: { Logo, CloseIcon },
};
</script>

<style lang="stylus">
@import "~@/styles/parts/sc.styl"
</style>
