<template>
  <div class="header__bottom">
    <h2 class="header__bottom-title">Общественные обсуждения</h2>
  </div>
</template>

<script>
export default {
  name: "HeaderPdBottomComponent",
};
</script>
