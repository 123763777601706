import axios from "axios";

/**
 * Запрос на получение страницы мэра
 * @param state
 * @param commit
 * @returns {Promise<void>}
 * @constructor
 */
async function GET_MAYOR_PAGE({ state, commit }) {
  await axios
    .get(state.api + "/api/mayor")
    .then(({ data }) => {
      commit("mayor_page", data);
    })
    .catch(({ response }) => {
      commit("mayor_page", {});
    });
}

/**
 * Запрос на получение страницы мэра
 * @param state
 * @param commit
 * @returns {Promise<void>}
 * @constructor
 */
async function GET_MAYOR_NEWS({ state, commit }) {
  await axios
    .get(state.api + "/api/mayornews")
    .then(({ data }) => {
      commit("mayor_news_page", data);
    })
    .catch(({ response }) => {
      commit("mayor_news_page", {});
    });
}

/**
 * Запрос на получение страницы медиа
 * @param state
 * @param commit
 * @returns {Promise<void>}
 * @constructor
 */
async function GET_MAYOR_MEDIA_PAGE({ state, commit }) {
  await axios
    .get(state.api + "/api/mayorgalleries")
    .then(({ data }) => {
      commit("mayor_media_page", data);
    })
    .catch(({ response }) => {
      commit("mayor_media_page", {});
    });
}

/**
 * Запрос на фильтр медиа
 * @param state
 * @param data
 * @returns {Promise<AxiosResponse<any>>}
 * @constructor
 */
async function POST_MAYOR_MEDIA_FILTER({ state }, data) {
  const url = data.url ? data.url : state.api + "/api/searchmayorgalleries";
  return await axios.post(url, data.data);
}

/**
 * Запрос на получение конкретной медиа
 * @param state
 * @param commit
 * @param id
 * @returns {Promise<void>}
 * @constructor
 */
async function GET_MAYOR_MEDIA_OPEN({ state, commit }, id) {
  await axios
    .get(state.api + "/api/mayorgallery/" + id)
    .then(({ data }) => {
      commit("mayor_media_open", data);
    })
    .catch(({ response }) => {
      commit("mayor_media_open", {});
    });
}

export {
  GET_MAYOR_PAGE,
  GET_MAYOR_MEDIA_PAGE,
  GET_MAYOR_NEWS,
  POST_MAYOR_MEDIA_FILTER,
  GET_MAYOR_MEDIA_OPEN,
};
