<template>
  <div>
    <div v-if="documents_arr && documents_arr.length" class="load__docs-list">
      <div v-for="(item, index) in documents_arr" :key="index" class="load__docs-item">
        <DocumentIcon />
        <div class="load__doc-info">
          <div class="load__doc-title">{{ fileName(item.name) }}</div>
          <div class="load__doc-size">
            <span>{{ fileType(item.name) }}</span> ({{ fileSizeFormat(item.size) }})
          </div>
        </div>
        <a @click.prevent="removeFile(index)" href="#">
          <DeleteIcon />
        </a>
      </div>
    </div>
    <input
      @change="handleFiles"
      type="file"
      :accept="accept"
      ref="docsInput"
      placeholder="Приложите файл"
      :multiple="multiple"
      class="hidden"
    />
    <button
      v-if="type === 'button'"
      @click="addDocument"
      class="btn-transpar_blue_dark btn--small"
      type="button"
    >
      {{ btnTitle }}
    </button>
    <button
      v-else-if="type === 'input' && !documents_arr.length"
      @click="addDocument"
      class="load__input-btn"
      type="button"
    >
      <span>{{ placeholder }}</span>
      <FileBindIcon />
    </button>
  </div>
</template>

<script>
import DocumentIcon from "@/components/svg/DocumentIcon.vue";
import DeleteIcon from "@/components/svg/DeleteIcon.vue";
import FileBindIcon from "@/components/svg/FileBindIcon.vue";

export default {
  name: "LoadFilesComponent",
  props: {
    btnTitle: {
      type: String,
      default: "Добавить документ",
    },
    type: {
      type: String,
      default: "button",
    },
    accept: {
      type: String,
      default() {
        return "image/jpeg, .doc, .docx, .pdf";
      },
    },
    multiple: {
      type: Boolean,
      default: true,
    },
    placeholder: {
      type: String,
      default() {
        return "Приложите файл";
      },
    },
  },
  data() {
    return {
      documents_arr: [],
    };
  },
  methods: {
    addDocument() {
      this.$refs.docsInput.click();
    },
    handleFiles() {
      const files = this.$refs.docsInput.files;
      if (!this.multiple) {
        this.documents_arr = [];
      }
      for (let i = 0; i < files.length; i++) {
        this.documents_arr.push(files[i]);
      }
      this.$emit("load", this.documents_arr);
      this.$refs.docsInput.value = null;
    },
    fileName(name) {
      return name.slice(0, name.lastIndexOf("."));
    },
    fileSizeFormat(size) {
      if (size < 1024) {
        return size + " Б";
      }
      if (size < 1048576) {
        return Math.ceil(size / 1024) + " КБ";
      }
      return (size / 1048576).toFixed(2) + " МБ";
    },
    fileType(name) {
      return name.slice(name.lastIndexOf(".") + 1, name.length);
    },
    removeFile(i) {
      this.documents_arr.splice(i, 1);
      this.$emit("load", this.documents_arr);
    },
  },
  components: {
    DocumentIcon,
    DeleteIcon,
    FileBindIcon,
  },
};
</script>

<style lang="stylus">
.load {
  &__input-btn {
    position relative
    background none
    border 1px solid var(--main_border_color)
    border-radius: 4px;
    padding 10px 14px
    font-size 1em
    line-height 24px
    color var(--main_border_color)
    width 100%
    text-align left
    cursor pointer

    .icon {
      position absolute
      right 14px
      top 10px
    }
  }

  &__docs-item {
    display flex
    align-items flex-start
    margin-bottom: 8px;
    gap 16px
  }

  &__doc-title {
    color var(--color_blue_dark)
    display block
    margin-bottom: 4px;
    word-break break-all
    +below(420px) {
      font-size 0.875em
      line-height 20px
    }
  }

  &__doc-size {
    font-size: 0.875em
    line-height: 20px;
    color var(--color_gray_dark)

    span {
      text-transform: uppercase;
    }
  }
}
</style>
