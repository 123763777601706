import axios from "axios";

/**
 * Получение данных главной страницы
 * @param state
 * @param commit
 * @returns {Promise<void>}
 * @constructor
 */
async function GET_HOME_PAGE({ state, commit }) {
  await axios
    .get(state.api + "/api/home")
    .then(({ data }) => {
      commit("home_page", data);
    })
    .catch(() => {
      commit("home_page", {});
    });
}

export { GET_HOME_PAGE };
