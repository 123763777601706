<template>
  <div class="header__bottom header__bottom-with-nav">
    <h2 class="header__bottom-title">
      <router-link :to="{ name: 'ic' }"> Идеи горожан </router-link>
    </h2>
    <nav class="header__nav">
      <ul class="header__nav-list">
        <li v-for="(item, index) in navLinks" :key="index" class="header__nav-item">
          <router-link
            v-if="item.route"
            :to="{ name: item.route }"
            :class="{ active: isRouteActive(item.route) }"
            class="header__nav-link"
          >
            {{ item.title }}
          </router-link>
          <a v-else @click.prevent="showSendIdeaModal" class="header__nav-link" href="#">{{ item.title }}</a>
        </li>
      </ul>
    </nav>
  </div>
</template>

<script>
import IdeaSendModal from "@/views/ic/components/modals/IdeaSendModal.vue";
import LoginModal from "components/modals/LoginModal.vue";

export default {
  name: "HeaderIcBottomComponent",
  data() {
    return {
      navLinks: [
        {
          title: "О проекте",
          route: "ic_about",
        },
        {
          title: "Предложить идею",
          route: "",
        },
        {
          title: "Все инициативы",
          route: "ic_all",
        },
        {
          title: "Реализованные инициативы",
          route: "ic_realized",
        },
      ],
    };
  },
  methods: {
    showSendIdeaModal() {
      const token = localStorage.getItem("apollo-token");
      if (token) {
        this.$store.dispatch("GET_USER", token).then(() => {
          this.$store.state._modals.push({
            component: IdeaSendModal,
            options: {
              categories: this.categories,
              user: this.$store.state.user,
            },
          });
        });
      } else {
        this.$store.state._modals.push({
          component: LoginModal,
        });
      }
    },
    isRouteActive(route) {
      return !!(
        this.$route.name === route ||
        (this.$route.meta &&
          this.$route.meta.parent &&
          this.$route.meta.parent.filter((p) => p.name === route).length)
      );
    },
  },
};
</script>
