import axios from "axios";

/**
 * Запрос на получение списка новостей
 * @param state
 * @param commit
 * @returns {Promise<void>}
 * @constructor
 */
async function GET_MAPS_PAGE({ state, commit }) {
  await axios
    .get(state.api + "/api/maps")
    .then(({ data }) => {
      commit("maps_page", data);
    })
    .catch(({ response }) => {
      commit("maps_page", {});
    });
}

/**
 * Запрос на получение конкретной новости
 * @param state
 * @param commit
 * @param id
 * @returns {Promise<void>}
 * @constructor
 */
async function GET_OPEN_MAP({ state, commit }, id) {
  await axios
    .get(state.api + "/api/map/" + id)
    .then(({ data }) => {
      commit("maps_one_page", data);
    })
    .catch(({ response }) => {
      console.log('Error')
      commit("maps_one_page", {});
    });
}



/**
 * Запрос на фильтр новостей
 * @param state
 * @param data
 * @returns {Promise<AxiosResponse<any>>}
 * @constructor
 */
async function POST_MAPS_FILTER({ state }, data) {
  const url = data.url ? data.url : state.api + "/api/searchmap";
  return await axios.post(url, data.data);
}

export { GET_MAPS_PAGE, GET_OPEN_MAP, POST_MAPS_FILTER };
