<template>
  <div class="mod">
    <div class="mod__header">
      <span class="mod__title">{{ options.title }}</span>
      <div class="mod__header-buttons">
        <button @click="close" type="button" class="mod__close">
          <CloseIcon />
        </button>
      </div>
    </div>
    <div class="mod__body mod__body-alert">
      <span class="mod__alert-text" v-html="options.text"> </span>
      <button @click="$emit('close')" class="mod__btn-close btn-blue btn--small">Закрыть</button>
    </div>
  </div>
</template>

<script>
import CloseIcon from "components/svg/CloseIcon.vue";

export default {
  name: "AlertModal",
  props: {
    options: Object,
  },
  mounted() {
    setTimeout(() => {
      this.close();
    }, 2500);
  },
  methods: {
    close() {
      this.$emit("close");
    },
  },
  components: {
    CloseIcon,
  },
};
</script>

<style lang="stylus">
@import "~@/styles/parts/mod.styl"
</style>
