import axios from "axios";

/**
 * Запрос на вход пользователя
 * @param state
 * @param token
 * @constructor
 */
async function GET_USER({ state }, token) {
  await axios
    .get(state.api + "/api/user/", {
      headers: {
        Authorization: "Bearer " + token,
      },
    })
    .then(({ data }) => {
      state.user = data;
    });
}

/**
 * Запрос на вход пользователя
 * @param state
 * @param data
 * @constructor
 */
async function POST_LOGIN({ state }, data) {
  return await axios.post(state.api + "/api/user/login", data, {
    headers: {
      Accept: "application/json",
    },
  });
}
/**
 * Запрос на регистрацию пользователя
 * @param state
 * @param data
 * @constructor
 */
async function POST_REGISTER({ state }, data) {
  return await axios.post(state.api + "/api/register", data, {
    headers: {
      Accept: "application/json",
    },
  });
}
/**
 * Запрос на сброс пароля пользователя
 * @param state
 * @param data
 * @constructor
 */
async function POST_RESET_PASSWORD({ state }, data) {
  return await axios.post(state.api + "/api/password/reset", data, {
    headers: {
      Accept: "application/json",
    },
  });
}
/**
 * Запрос на получение токена на сброс пароля пользователя
 * @param state
 * @param data
 * @constructor
 */
async function POST_RESTORE_PASSWORD({ state }, data) {
  return await axios.post(state.api + "/api/password/email", data, {
    headers: {
      Accept: "application/json",
    },
  });
}

export { POST_LOGIN, GET_USER, POST_REGISTER, POST_RESET_PASSWORD, POST_RESTORE_PASSWORD };
