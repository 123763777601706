import axios from "axios";

/**
 * Запрос на получение списка новостей
 * @param state
 * @param commit
 * @returns {Promise<void>}
 * @constructor
 */
async function GET_NEWS_PAGE({ state, commit }) {
  await axios
    .get(state.api + "/api/news")
    .then(({ data }) => {
      commit("news_page", data);
    })
    .catch(({ response }) => {
      commit("news_page", {});
    });
}

/**
 * Запрос на получение конкретной новости
 * @param state
 * @param commit
 * @param id
 * @returns {Promise<void>}
 * @constructor
 */
async function GET_OPEN_NEWS({ state, commit }, id) {
  await axios
    .get(state.api + "/api/news/" + id)
    .then(({ data }) => {
      commit("news_open_page", data);
    })
    .catch(({ response }) => {
      commit("news_open_page", {});
    });
}

async function GET_ACTUAL_NEWS({ state, commit }) {
  await axios
    .get(state.api + "/api/actualnews")
    .then(({ data }) => {
      commit("news_actual", data);
    })
    .catch(({ response }) => {
      commit("news_actual", {});
    });
}

/**
 * Запрос на получение типов новостей
 * @param state
 * @param commit
 * @returns {Promise<void>}
 * @constructor
 */
async function GET_NEWS_TYPES({ state, commit }) {
  await axios
    .get(state.api + "/api/newstypes")
    .then(({ data }) => {
      commit("news_types", data);
    })
    .catch(({ response }) => {
      commit("news_types", {});
    });
}

/**
 * Запрос на фильтр новостей
 * @param state
 * @param data
 * @returns {Promise<AxiosResponse<any>>}
 * @constructor
 */
async function POST_NEWS_FILTER({ state }, data) {
  const url = data.url ? data.url : state.api + "/api/searchnews";
  return await axios.post(url, data.data);
}

export { GET_NEWS_PAGE, GET_OPEN_NEWS, GET_ACTUAL_NEWS, GET_NEWS_TYPES, POST_NEWS_FILTER };
