import axios from "axios";

/**
 * Запрос изменение почты
 * @param state
 * @param data
 * @constructor
 */
async function POST_EDIT_EMAIL({ state }, data) {
  return await axios.post(state.api + "/api/editemail", data.data, {
    headers: {
      Authorization: "Bearer " + data.token,
    },
  });
}

/**
 * Запрос изменение пароля
 * @param state
 * @param data
 * @constructor
 */
async function POST_EDIT_PASSWORD({ state }, data) {
  return await axios.post(state.api + "/api/editpassword", data.data, {
    headers: {
      Authorization: "Bearer " + data.token,
    },
  });
}

/**
 * Запрос изменение персональных данных
 * @param state
 * @param data
 * @constructor
 */
async function POST_EDIT_PERSONAL_DATA({ state }, data) {
  return await axios.post(state.api + "/api/editperosnaldata", data.data, {
    headers: {
      Authorization: "Bearer " + data.token,
    },
  });
}

/**
 * Запрос на получение уведомлений
 * @param state
 * @param commit
 * @param data
 * @returns {Promise<void>}
 * @constructor
 */
async function GET_USER_NOTIFICATIONS({ state, commit }, data) {
  const url = data.url ? data.url : state.api + "/api/newsnotifications";
  await axios
    .get(url, {
      headers: {
        Authorization: "Bearer " + data.token,
      },
    })
    .then(({ data }) => {
      commit("user_notifications", data);
    })
    .catch(({ response }) => {
      commit("user_notifications", {});
    });
}

/**
 * Запрос на получение уведомлений
 * @param state
 * @param commit
 * @param data
 * @returns {Promise<void>}
 * @constructor
 */
async function GET_USER_APPEALS({ state, commit }, data) {
  const url = data.url ? data.url : state.api + "/api/userappeals";
  await axios
    .get(url, {
      headers: {
        Authorization: "Bearer " + data.token,
      },
    })
    .then(({ data }) => {
      commit("user_appeals", data);
    })
    .catch(({ response }) => {
      commit("user_appeals", {});
    });
}

/**
 * Удаление профиля
 * @param state
 * @param token
 * @returns {Promise<AxiosResponse<any>>}
 * @constructor
 */

async function POST_DELETE_ACCOUNT({ state }, token) {
  return await axios.post(
    state.api + "/api/deleteaccount",
    {},
    {
      headers: {
        Authorization: "Bearer " + token,
      },
    }
  );
}

export {
  POST_EDIT_EMAIL,
  POST_EDIT_PASSWORD,
  POST_EDIT_PERSONAL_DATA,
  GET_USER_NOTIFICATIONS,
  GET_USER_APPEALS,
  POST_DELETE_ACCOUNT,
};
