import Vue from "vue";
import VueApollo from "vue-apollo";
import fetch from "node-fetch";
import { ApolloClient } from "apollo-client";
import { InMemoryCache } from "apollo-cache-inmemory";
import { createUploadLink } from "apollo-upload-client";

Vue.use(VueApollo);

export default function createApollo() {
  const cache = new InMemoryCache();
  const socialControlClient = new ApolloClient({
    link: createUploadLink({
      uri: "https://mkala.ru/scgraphql",
      fetch: fetch,
    }),
    cache,
  });
  const publicDiscussionClient = new ApolloClient({
    link: createUploadLink({
      uri: "https://mkala.ru/pdgraphql",
      fetch: fetch,
    }),
    cache,
  });
  const cityIdeasClient = new ApolloClient({
    link: createUploadLink({
      uri: "https://mkala.ru/icgraphql",
      fetch: fetch,
    }),
    cache,
    defaultOptions: {
      watchQuery: {
        fetchPolicy: "no-cache",
        errorPolicy: "ignore",
      },
      query: {
        fetchPolicy: "no-cache",
        errorPolicy: "all",
      },
    },
  });
  return new VueApollo({
    clients: {
      sc: socialControlClient,
      pd: publicDiscussionClient,
      ic: cityIdeasClient,
    },
    defaultClient: socialControlClient,
  });
}
